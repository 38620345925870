@import "colors";
/* stylelint-disable no-descending-specificity */
@keyframes clicker-fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.5;
    }
}

.wip-menu-icon,
.wip-menu-grabber {
    display: inline-block;
    position: relative;
    z-index: 20;
    width: 2rem;
    text-align: center;
    line-height: 1em;
    flex-grow: 0;
    margin: 0 0.5rem;
    cursor: pointer;

    &.static-position {
        position: static;
    }
}

.wip-menu-icon {
    i.fas {
        color: $brand-primary;
        font-size: 1.6rem;
        vertical-align: baseline;
        display: block;
        padding-top: 1rem;

        &.no-padding {
            padding-top: 0;
        }
    }
}

.wip-menu-grabber {
    float: left;

    i.fas {
        color: $charcoal4;
        line-height: 1.5em;
        font-size: 1.6rem;
        vertical-align: unset;
    }
}

.document-settings {
    margin-bottom: 2rem;

    .document-setting {
        width: 32.5%;
        text-align: center;
        padding: 0;
        margin: 2rem auto;

        h4 {
            color: $brand-primary;
        }

        p {
            margin: 0.25rem auto;
            font-size: 1.4rem;
            color: $charcoal2;
        }
    }
}

.pres-prev {
    margin: 1rem auto;
    width: 96%;
    box-sizing: border-box;
    border: 1px solid $charcoal5;
    background: $white;
    box-shadow: 0 4px 12px -6px rgb(0 0 0 / 50%);

    img {
        width: 100%;
        height: auto;
        display: block;
        box-sizing: content-box;
    }
}

.checklist-top {
    h5:first-of-type {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    hr {
        &:first-of-type,
        &:nth-of-type(2) {
            margin-top: 0;
            margin-bottom: 2rem;
            border: 0;
            border-bottom: 1px solid $charcoal5;
            width: 100%;
            position: absolute;
            left: 0;
        }
    }
}

.wip-checklist-section,
.checklist-section {
    box-sizing: border-box;
    margin: 4rem auto;
    padding: 0;
    transition: all 0.2s ease-in-out;

    &.closed {
        border: none;
        height: 6rem;
        overflow: hidden;
    }

    &.open {
        border: 1px solid $charcoal6;
        height: auto;
    }

    h5 {
        margin: 1rem 0 1rem 2rem;
    }

    .wip-checklist-header,
    .checklist-header {
        background: $charcoal6;
        height: 6rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        z-index: 20;
        padding-right: 1rem;

        .snipe {
            position: absolute;
            z-index: 100;
            width: 1rem;
            height: 1rem;
            top: 2px;
            left: 2px;
            border-radius: 2px;

            &.done {
                border-top: 10px solid $sei-green;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid $sei-green;
            }

            &.alert {
                border-top: 10px solid $sei-orange;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid $sei-orange;
            }

            &.error {
                border-top: 10px solid $sei-red;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid $sei-red;
            }
        }

        h4 {
            width: 70%;
            float: left;
            padding-left: 2rem;
            line-height: 1em;
            flex-grow: 9;
        }

        .prebal-block {
            font-size: 1.6rem;
            font-weight: 700;
            text-align: center;
            line-height: 1em;
            margin: 0 0.5rem;
            flex-grow: 0;

            &::first-line {
                font-weight: 400;
                font-size: 10px;
            }
        }

        .wip-menu {
            position: absolute;
            z-index: 1000;
            top: 40px;
            right: -30px;
            width: 240px;
            color: $charcoal5;
            background: $white;
            height: auto;
            line-height: 30px;
            border-radius: 5px;
            box-sizing: border-box;
            box-shadow: 0 4px 10px rgb(0 0 0 / 25%);

            /* top pointer */
            i.fas {
                line-height: 1em;
                font-size: 1.5rem;
                vertical-align: baseline;
            }

            &::after {
                content: "";
                position: absolute;
                z-index: 10;
                top: -10px;
                left: 80%;
                width: 0;
                height: 0;
                border-bottom: 10px solid $white;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
            }

            & .closeit {
                height: 1.6rem;
                padding-top: 1rem;
                padding-right: 1rem;
                margin-bottom: 0.5rem;
                color: $charcoal5;
                text-align: right;
                font-size: 1.4rem;
                line-height: 1em;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    text-align: center;
                    font-size: 1.2rem;
                    line-height: 1em;
                    display: block;
                    margin: 0;
                    padding: 1rem;

                    &.warning {
                        background: rgb(210 30 10 / 10%);
                        padding: 1rem;
                        color: $charcoal1;
                        border: 2px solid $white;
                        -moz-border-radius-bottomright: 5px;
                        -moz-border-radius-bottomleft: 5px;
                        border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 5px;

                        h5 {
                            display: block;
                            width: auto;
                            float: none;
                            color: $sei-red;
                            text-align: center;
                        }

                        .primary {
                            margin: 1rem auto 0;

                            i {
                                color: $sei-red;
                            }
                        }
                    }
                }
            }
        }
    }

    .tax-rate-save-failed-info {
        color: $brand-primary;
        padding-bottom: 0.5rem !important;
        padding-top: 0.5rem !important;
    }

    .icon-blue {
        margin-left: 2.7rem;
        margin-right: 0.8rem;
        color: $brand-primary;
    }

    .checklist-header {
        padding: 1rem 0;

        h4 {
            line-height: 1.5em;
        }
    }

    .wip-goals-summary {
        padding-bottom: 1rem;
    }

    .checklist-list {
        position: relative;
        display: flow-root;
        text-align: left;
        border: 1px solid $charcoal6;
        padding: 1rem 0;
        box-sizing: border-box;
        overflow: visible;

        &.active {
            background-color: $sei-blue-10;
            border: none;
        }

        &.normal {
            transition: all 0.2s ease-in-out;

            &:hover {
                border: 1px solid $sei-blue-50;
            }
        }

        .view-icon {
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            position: absolute;
            z-index: 100;
            top: 5px;
            right: -25px;

            i.fas {
                color: $charcoal4;
                font-size: 1.2rem;
                text-align: center;
                vertical-align: unset;
            }
        }

        .edit-icon {
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            display: inline-block;

            i.fas {
                color: $charcoal4;
                font-size: 1.2rem;
                text-align: center;
                vertical-align: unset;
            }

            margin-left: 0.5rem;
        }

        .wip-menu-grabber {
            margin: 0 1rem 0 0;
            text-align: left;
            width: auto;
        }

        .checklist-list-title {
            display: flow-root;

            .wip-menu-grabber {
                float: left;

                i.fas {
                    line-height: 2em;
                }
            }
        }

        h2 {
            color: $brand-primary;
        }

        .toggle-row {
            margin: 0.5rem 0;

            .toggle-row-left {
                text-align: left;

                .checklist-form-control {
                    position: relative;
                    z-index: 20;
                    display: inline-block;
                    width: 49%;
                }

                &.indent {
                    padding-left: 1rem;
                }

                &.indent2 {
                    padding-left: 2rem;
                }

                &.indent3 {
                    padding-left: 3rem;
                }

                &.indent4 {
                    padding-left: 4rem;
                }
            }

            .toggle-row-right {
                text-align: right;

                .toggle-sml-algn {
                    display: inline-block;
                    width: 10rem;
                    margin-right: 6rem;
                    text-align: left;
                }
            }

            .toggle-row-center {
                padding-left: 5%;
            }

            &.title {
                margin: 1rem 0 1.5rem;
            }

            .fas.fa-undo {
                margin-left: 1rem;
                color: $brand-primary;
            }

            .fas.disabled,
            .fas[disabled],
            .disabled > .fas,
            [disabled] > .fas {
                color: $charcoal4;
            }
        }

        select {
            position: relative;
            z-index: 10;
        }

        .color-block {
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            border-radius: 1px;
            border: 1px solid $charcoal5;
            background-color: $white;
            position: absolute;
            z-index: 100;
            top: 9px;
            right: 30px;
            transition: background-color 0.2s ease-out;
        }

        &#checklist-proposal {
            p {
                display: inline-block;
                margin: 0;
                padding: 0;
                width: 40%;
            }
        }

        .preview-span {
            display: none;
        }

        &.normal:hover > span {
            display: inline;
            font-size: 12px;
            line-height: 2.5em;
            color: $white;
            font-weight: 700;
            text-align: center;
            background-color: $brand-primary;
            width: 120px;
            height: 32px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            position: absolute;
            z-index: 100;
            top: 0;
            left: 50%;
            margin-left: -60px;
            opacity: 0.5;
            cursor: pointer;
            animation-name: clicker-fade;
            animation-duration: 0.5s;
            animation-timing-function: ease-in-out;
        }
    }

    .wip-summary {
        font-size: 1.6rem;
        line-height: 1em;
        color: $charcoal1;
        margin: 0;

        &.green {
            background-color: $sei-green-10;
        }

        .wip-summary-spacer {
            display: block;
            margin: 1rem auto;
        }

        .success-message {
            padding: 1.5rem 2rem 0.2rem !important;
        }

        .wip-summary-row {
            box-sizing: border-box;
            font-size: 1.4rem;
            color: $charcoal2;
            margin: 0 0 0 3.125rem;
            padding: 0.5rem 2rem 0.5rem 1.5rem;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            &.sectionhead {
                background-color: $white;
                padding: 1rem 2rem;
                font-size: 1.6rem;
                margin: 0;
                border-top: 1px solid #ddd;
                border-bottom: none;
            }

            &.head {
                padding: 1.5rem 2rem 1rem;
                font-size: 1.6rem;
                margin: 0;
            }

            &.subhead {
                padding: 0.5rem 2rem;
                font-size: 1.6rem;
                margin: 0;
            }

            &.sublevel {
                padding: 0.25rem 2rem;
                margin-top: -1.33rem;
                margin-bottom: 0.3125rem;
                font-size: 1.2rem;
            }

            &.whitegap {
                background-color: $white;
                padding-bottom: 1rem;
                margin: 0.5rem auto;
            }

            &.blankgap {
                background-color: transparent;
                padding-bottom: 1rem;
                margin: 0.5rem auto;
            }

            p {
                margin: 0;
                font-size: 1.4rem;
                color: $charcoal1;
                text-align: left;
                display: inline-block;
                letter-spacing: 0.01em;
                line-height: 1em;

                &.head {
                    font-weight: 400;
                    font-size: 1.6rem;
                }

                &.subhead {
                    font-weight: 700;
                }

                &.sml {
                    font-size: 1.2rem;
                    display: inline-block;
                    letter-spacing: normal;
                    margin-left: 2rem;
                }

                &.xsml {
                    font-size: 1rem;
                    display: inline-block;
                    letter-spacing: normal;
                    margin-left: 2rem;
                }

                &.wrap {
                    display: block;
                    line-height: 1em;
                    margin: 0;
                    padding: 0;
                }
            }

            .wip-summary-left {
                width: 65%;
                text-align: left;

                &.transition-success {
                    width: 95%;
                    text-align: left;
                    padding-left: 0.25rem;
                    padding-top: 0.1rem;
                }

                &.success-icon {
                    width: auto;
                    text-align: left;
                }
            }

            .wip-summary-right {
                width: 32%;
                text-align: right;
                color: $charcoal1;

                .wip-summary-icon,
                .wip-summary-btn {
                    display: inline-block;
                    font-size: 1.6rem;
                    font-weight: 400;
                    float: right;
                    text-align: center;
                    margin: 0.5rem 0 0.5rem 2rem;
                    color: $brand-primary;
                    cursor: pointer;
                    line-height: 1em;

                    i.fas {
                        line-height: 1em;
                        font-size: 1.6rem;
                        vertical-align: baseline;
                        padding-left: 2rem;
                        border-left: 1px solid $charcoal5;
                    }
                }

                .wip-summary-btn {
                    margin-top: -4px;
                    margin-bottom: 5px;

                    i.fas {
                        padding-left: 1rem;
                        border-left: none;
                    }
                }

                &.success-download {
                    width: auto;
                    margin-left: 2rem;
                    margin-top: 0.2rem;
                }
            }
        }

        .fee-margin-left {
            margin-left: 1.8rem;
        }

        .fee-bottom-border {
            border-bottom: 0.1rem solid $charcoal5;
            margin-bottom: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}

#checklist-wip-right {
    float: right;
    text-align: center;
    height: 100%;
    padding-top: 2rem;

    img {
        display: initial;
        width: 25%;
    }
}

#checklist-wip > .right-modal-content {
    h5:first-of-type {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    hr {
        &:first-of-type,
        &:nth-of-type(2) {
            margin-top: 0;
            margin-bottom: 2rem;
            border: 0;
            border-bottom: 1px solid $charcoal5;
            width: 100%;
            position: absolute;
            left: 0;
        }
    }
}

@media screen and (width >= 992px) {
    .toggle-row {
        font-size: 1.4rem;
    }
}

@media screen and (width >= 1200px) {
    .toggle-row {
        font-size: 1.6rem;
    }
}

.hr {
    border-top: 1px solid $brand-primary;
    margin: 2rem auto 0;
    padding-bottom: 2rem;
    width: 50%;
}

@media screen and (width >= 768px) {
    .hr {
        width: 100%;
        opacity: 0.2;
        margin: 4rem auto 0;
        padding-bottom: 4rem;
    }
}

.unselectable {
    -webkit-touch-callout: none;
    user-select: none;
}

#pick-list-demo {
    position: relative;
    z-index: 100;
}

.checklist-section {
    &.open {
        border: none;
    }
}

@media screen and (width >= 992px) {
    .pres-prev {
        width: 90%;
    }
}

@media screen and (width >= 1200px) {
    .pres-prev {
        width: 84%;
    }
}
