sei-car-proposal-tax-transition-analysis,
sei-car-account-checklist {
    .zero-padding {
        padding-bottom: 0 !important;
    }

    .market-margin-bottom {
        margin-bottom: 0.5rem !important;
    }

    .icon-color-blue {
        margin-left: 0.2rem;
        color: $brand-primary;
    }

    .market-margin-top {
        margin-top: 0.5rem !important;
    }

    .text-bold {
        font-weight: bold;
    }

    .delete-error {
        color: #8a6d3b !important;
    }

    .current-account-page {
        .current-account-form {
            padding-top: 1.6rem;
        }

        .sei-margin-bottom-md {
            margin-bottom: 0;
        }

        .account-details-header {
            margin-top: 2rem;
        }

        label {
            font-size: 1.4rem;
            line-height: 1.4;
        }

        h3 {
            line-height: initial;
            margin: 1.3rem 0;
        }

        .padding {
            line-height: 1.5;
        }

        .right-modal-container {
            position: fixed;
            z-index: 1032;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(0 0 0 / 40%);
        }

        .right-modal-content {
            position: relative;
            float: right;
            background-color: $white;
            border: 0.0625rem solid $gray-lighter;
            width: 50%;
            height: 100%;

            @include flex-display;
            @include flex-direction(column);
            @include flex-wrap(nowrap);

            transition: width 0.3s ease-in-out;
            box-shadow: -0.3125rem 0.0625rem 1rem 0.0625rem rgb(45 45 45 / 40%);
        }

        .right-modal-body {
            flex-grow: 1;
            min-height: 2em;
            overflow-y: auto;
            padding: 4rem 4rem 0;
            border-bottom: 1.25rem solid $white;
        }

        .right-modal-footer {
            flex-shrink: 0;
            border-top: 0.0625rem solid $gray-darker;
            padding: 0.875rem 0.875rem 0.875rem 2.5rem;
            bottom: 0;
            min-height: 3.75rem;
            width: 100%;
            background: linear-gradient(180deg, $white 0%, $gray-lighter 100%);
        }

        .delete-error {
            margin-top: 0 !important;
        }

        .worksheet-footer {
            overflow-y: auto;
        }

        .message-details {
            margin-bottom: 30px;
        }

        .exception-pop-up-modal {
            .sei-modal-window {
                background-color: rgb(0 0 0 / 20%);
            }
        }
    }

    .delete-current-account-modal {
        h4 {
            color: inherit;
        }
    }

    sei-ias-file-upload-content {
        .file-upload-box {
            line-height: 1.5;
        }
    }

    sei-ias-file-upload-modal {
        .file-upload-footer {
            .complete-button {
                padding-right: 1rem;
            }
        }
    }

    .account-name {
        align-self: center;
    }

    .market-visible {
        display: none !important;
    }

    .file-action-label {
        font-size: 1.6rem !important;
        line-height: 1.6 !important;
    }

    bs-tooltip-container {
        top: -5rem !important;
    }
}
