@import "colors";
@import "mixins";

/* stylelint-disable no-descending-specificity */
.hud-wrapper {
    /* used to hold the HUD in place and resize accordingly */
    width: 95%;
    position: fixed;
    z-index: 1100;
    bottom: 8rem;
    right: 1rem;
    padding: 2rem 6.5rem;
    text-align: right;

    &.demo {
        position: relative;
        bottom: auto;
        float: right;
        margin-left: 20%;

        h4 {
            text-align: left;
            display: block;
            color: $charcoal3;
            padding-left: 1rem;
        }
    }
}

.hud {
    position: relative;
    z-index: 100;
    display: inline-block;
    background: rgb(45 45 45 / 80%);
    border-radius: 1rem;
    box-sizing: border-box;
    border: 0.1rem solid rgb(255 255 255 / 20%);
    overflow: hidden;
    min-height: 7.75rem;

    /*  add-remove open or closed class for TAB collapsing-expanding */
    &.open {
        width: 100%;
    }

    &.closed {
        width: 30%;
    }

    .hud-main {
        width: 100%;
        box-sizing: border-box;
        padding-right: 3.6rem;

        /* allows space for the tab on right size of HUD */
        background-color: transparent;

        .flex-row {
            &.attributes {
                font-size: 1.2rem;
                letter-spacing: -0.0125em;
                font-weight: 400;
                color: $charcoal6;
                line-height: 1em;
                border-top: 0.1rem solid $charcoal1;
                justify-content: center;

                &:first-child {
                    border-top: none;
                }

                .indicator-percentage {
                    margin: 0;
                    color: $white;
                }

                .indicator-remaining,
                .indicator-completed {
                    height: 0.5rem;
                    border-radius: 0.5rem;
                }

                .indicator-remaining {
                    background-color: $white;
                }

                .indicator-completed {
                    background-color: $sei-yellow;
                }

                .flx-sz {
                    padding: 0.65rem 1rem 0 0;
                    border-right: 0.1rem solid $charcoal1;

                    &:last-child {
                        border-right: none;
                    }

                    &.no-border {
                        border: none;
                    }

                    &.padding {
                        padding-top: 2rem;
                    }
                }

                &.minor {
                    text-align: center;
                    line-height: 1.2em;

                    span {
                        display: block;

                        &:last-child {
                            font-weight: 700;
                            color: $white;
                        }
                    }
                }

                &.major {
                    .flx-sz {
                        padding: 1.5rem 0.2rem;
                    }

                    span {
                        display: inline-block;

                        &:last-child {
                            font-weight: 600;
                            font-size: 1.5rem;
                            color: $white;
                            margin-left: 0.5rem;
                            vertical-align: middle;
                        }
                    }

                    &.left-stack {
                        text-align: left;

                        .flx-sz {
                            padding: 0.375rem 0 0.5rem 1rem;
                        }

                        span {
                            display: block;
                            text-align: left;

                            &:last-child {
                                font-weight: 700;
                                font-size: 1.5rem;
                                line-height: 1.1em;
                                color: $white;
                                margin-left: auto;
                                vertical-align: inherit;
                            }
                        }
                    }
                }

                &.dblock {
                    display: inline-block;
                    float: left;
                    border-right: 0.1rem solid $charcoal1;
                    min-height: 7.75rem;

                    .flx-sz {
                        padding: 1rem 0.5rem;
                        text-align: center;
                    }

                    .pill {
                        margin: 2.5rem 0;
                    }
                }

                &.single {
                    text-align: center;
                    line-height: 1.2em;

                    &.compressed {
                        padding: 0 1rem;
                    }

                    .flx-sz {
                        padding: 1.5rem 0.2rem;
                    }

                    span {
                        display: block;

                        &:last-child {
                            font-weight: 600;
                            font-size: 1.5rem;
                            color: $white;
                            margin-left: 0.5rem;
                            vertical-align: middle;
                            padding-top: 1.3rem;
                        }
                    }
                }
            }

            .fees-ribbon {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-evenly;
                font-size: 1.1rem;
                border: 0.1rem solid $charcoal5 !important;
                margin-left: 1rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                position: relative;
                min-width: fit-content;
            }

            .fee-strategy-text {
                span {
                    &:first-child {
                        padding-top: 2rem;
                    }

                    &:last-child {
                        margin-top: 0.5rem;
                    }
                }
            }

            .fee-strategy-text-compressed span {
                padding-top: 0 !important;
                margin-top: 0;
            }

            .fee-strategy {
                position: relative;
            }

            .arrow-left {
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-right: 10px solid $charcoal5;
                position: absolute;
                z-index: 100;
                top: 15px;
                border-radius: 2px;
                left: -10px;
            }
        }
    }

    .hud-tab {
        /* tab sits on top of HUD, Hud main requires padding on right to allow for this */
        position: absolute;
        z-index: 250;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        color: $charcoal6;
        width: 3.6rem;
        text-align: center;
        min-height: 7.75rem;

        i.fas {
            /* custom stuff for the icons on the tab */
            display: block;
            box-sizing: border-box;
            padding-left: 0.125rem;
            margin: 1.2rem auto 2rem;
        }

        .fas-info {
            position: absolute;
            bottom: 0.7rem;
            left: 25%;
            margin-bottom: 10% !important;
        }
    }

    .loading-spinner {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.fee-strategy-ribbon-border {
    border-top: 0.1rem solid $charcoal4;
}

.fee-strategy-ribbon p,
.fee-strategy-ribbon span {
    margin: 0;
    margin-top: 0.5rem;
}

.fee-strategy-ribbon {
    margin-left: 4rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 75%;
}

.hud-wrapper-closed {
    padding: 0 6rem 2rem 0;
}

.pill.compressed {
    margin: -2rem 0 1rem;
}

/* resizes HUD wrapper for worksheet resizing */
@media screen and (width >= 768px) {
    .hud-wrapper {
        width: 80%;
    }

    .hud-wrapper-closed {
        width: 28%;
    }
}

@media screen and (width >= 1200px) {
    .hud-wrapper {
        width: 50%;
    }

    .hud-wrapper-closed {
        width: 14%;
    }
}
