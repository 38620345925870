.upload-button {
    width: 1.6rem;
    height: 1.6rem;
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
    top: 0.5rem;
    right: -2.5rem;
}

.file-upload-box {
    height: 100%;
    flex-direction: column;
    color: $charcoal3;

    label {
        font-weight: normal;
    }

    .file-main-label {
        font-size: 2.1rem;
    }

    .file-upload-color-icon {
        color: $charcoal4;
    }

    .dropzone {
        border: 0.2rem dashed $charcoal5;
        border-radius: 0.5rem;
        flex-grow: 1;
        margin: 0;
    }

    .drop-items {
        flex-direction: column;
        justify-content: center;
    }

    .drop-row {
        align-self: center;
        padding-bottom: 0.5rem;
    }

    .drop-file-counter {
        bottom: 0;
        position: absolute;
        align-self: flex-end;
        color: $charcoal4;
    }

    .action-label {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    input[type="file"] {
        display: none;
    }
}

.file-upload-list {
    .file-record {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    .file-name {
        color: $charcoal3;
        font-weight: bold;
    }

    .file-delete-icon {
        padding-left: 1rem;
        vertical-align: middle;
        color: $sei-dark-red;
    }
}

.file-upload-body {
    label {
        font-weight: normal;
    }

    .upload-document-header {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }

    .upload-document-label {
        color: $charcoal2;
        font-weight: bold;
        padding-right: 1.5rem;
    }

    .action-label {
        padding-left: 1.5rem;
    }

    .nav-pills > li > a {
        border-radius: 0;
    }

    .menu {
        div {
            border: 0.1rem solid $brand-primary;
            border-left: 0;
        }

        div:first-child {
            border: 0.1rem solid $brand-primary;
        }

        div:hover,
        div:focus {
            background-color: $brand-primary;
            cursor: pointer;
        }
    }

    .nav-pills {
        li {
            border: 0.1rem solid $brand-primary;
            border-left: 0;
        }

        li.active {
            background-color: $brand-primary;
        }

        li > a {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        li > a:hover,
        li > a:focus {
            background-color: transparent;
        }

        li:first-child {
            border: 0.1rem solid $brand-primary;
        }
    }
}

.car-centered-modal-footer {
    height: fit-content !important;
}

.file-upload-footer {
    .cancel-button {
        padding-left: 2rem;
    }
}

.file-upload-container {
    .file-upload-content {
        border: 0.1rem solid $brand-primary;
    }

    .centered-modal-body-fit-height {
        height: 0;
    }
}

.file-upload-summary {
    .upload-summary-container {
        padding-top: 1.5rem;
    }

    .upload-summary-row {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}
