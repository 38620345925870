@import "colors";
/*stylelint-disable*/
.risk-widget {
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: row nowrap;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    .risk-level {
        position: relative;
        z-index: 100;
        width: 33%;
        height: auto;
        background-color: $white;
        color: $charcoal2;
        font-size: 1.4rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: 400;
        border-bottom: 1px solid $charcoal5;
        border-right: 1px solid $charcoal5;
        padding: 0.5em 0 0;
        margin: 0;

        &._low {
            &::after {
                content: "low";
            }

            &._active {
                background-color: rgb(102 149 61 / 10%);
                border-bottom: 1px solid $sei-green;
            }
        }

        &._med {
            &::after {
                content: "med";
            }

            &._active {
                background-color: rgb(255 138 0 / 10%);
                border-bottom: 1px solid $sei-orange;
            }
        }

        &._high {
            &::after {
                content: "high";
            }

            &._active {
                background-color: rgb(210 30 5 / 10%);
                border-bottom: 1px solid $sei-red;
            }
        }

        .risk-increment {
            display: block;
            position: absolute;
            z-index: 20;
            width: 32%;
            height: 3rem;
            border-top: 0.5rem solid $charcoal5;
            background-color: rgb(17 198 177 / 0%);
            top: 0;
            transition: all 0.2s ease-in-out;

            &._active {
                border-top: 0.5rem solid $sei-teal;

                &::after {
                    content: "";
                    position: absolute;
                    z-index: 10;
                    top: 0;
                    left: var(--risk-tol-var);
                    margin-left: -5px;
                    width: 0;
                    height: 0;
                    border-top: 5px solid $sei-teal;
                    border-right: 5px solid transparent;
                    border-left: 5px solid transparent;
                }
            }

            &._snap {
                border-top: 0.5rem solid $charcoal5;

                &::after {
                    top: -0.7rem;
                    margin-left: -0.5rem;
                    border: 0.5rem solid $sei-teal;
                    border-radius: 1rem;
                }
            }

            &._highlight::after {
                margin-left: -0.4rem;
                border: 0.4rem solid $sei-teal;
            }

            &:hover {
                border-top: 0.5rem solid $sei-teal;
                background-color: rgb(17 198 177 / 20%);
                cursor: pointer;
                border-radius: 2px;

                &::after {
                    border-top: none;
                    border-right: none;
                    border-left: none;
                }
            }

            &._disabled {
                pointer-events: none;
            }

            &._full {
                width: 33%;
                border-top: 0.3rem solid #d2d2d2;
            }
        }

        :nth-child(1) {
            left: 1%;
        }

        :nth-child(2) {
            left: 34%;
        }

        :nth-child(3) {
            left: 67%;
        }
    }

    :nth-child(3) {
        border-right: none;
    }
}

.risk-widget-key {
    text-align: right;

    .risk-preference,
    .risk-capacity,
    .risk-investment {
        font-size: 1rem;
        font-weight: 400;
        color: $charcoal1;
        letter-spacing: 0.02em;
        text-align: right;
        display: inline-block;
        padding: 0.5rem;
        margin-left: 0.9rem;
    }
}

.risk-icon {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 0.5rem;

    &._bias {
        background-color: $sei-dark-purple;
    }

    &._capacity {
        background-color: $sei-dark-blue;
        transform: rotate(45deg);
    }

    &._investment {
        background-color: $sei-teal;
        border-radius: 1rem;
    }

    &._1,
    &._2,
    &._3,
    &._4,
    &._5,
    &._6,
    &._7,
    &._8 {
        position: absolute;
        z-index: 500;
        top: 2.5rem;
        width: 1rem;
        height: 1rem;
    }

    &._0 {
        display: none;
    }

    &._1 {
        left: 5%;
    }

    &._2 {
        left: 16%;
    }

    &._3 {
        left: 27%;
    }

    &._4 {
        left: 38%;
    }

    &._5 {
        left: 49%;
    }

    &._6 {
        left: 60%;
    }

    &._7 {
        left: 71%;
    }

    &._8 {
        left: 82%;
    }
}

.risk-widget-right {
    margin-right: -1.5rem;
}

.risk-widget-left {
    margin-left: 1.5rem;
}
