$car-light-gray: #f0f0f0;
$car-gray-indicator: #d1d1d1;
$car-gray-label: #9e9e9e;
$car-green-indicator: #579706;
$car-orange-indicator: #fe8300;
$car-yellow-indicator: #efd900;
$car-light-yellow: #efedd7;
$car-snow-drift: #e7e7e6;
$car-dark-red: #9d0000;
$car-dark-blue: #073a6f;
$car-light-blue: #027fab;
$car-color-secondary-blue: #d7e4e8;
$car-gray-progress-line-indicator: #787878;
$car-gray-footer-summary: #5a5a5a;
$car-white: #fff;
$car-gray-toggle-hover: #333;
$car-black: #000;
$car-dark-gray: #2d2d2d;
$car-gray-input-color: #555;
$car-gray-input-group-addon-border: #ccc;
$car-gray-input-border-hover: #a6a6a6;
$car-input-color-hover: #0d0d0d;
$car-light-red: #f9cccc;
$car-light-pink: #e94646;
$car-light-gray-optional: #a0a0a0;
$car-blue: #037ea6;

/* Using rgba to handle proper transparency of #66953d1a */
$car-light-olive: rgb(102 149 61 / 10%);
$car-light-yellow-secondary: #fdfbe5;

/* Starting to fix and standardize our variables. working with Tony on naming conventions. */
$car-color-primary-gray: #d1d1d1;
$car-color-secondary-gray: #f0f0f0;
$car-light-shadow-black: rgb(0 0 0 / 12%);
$car-medium-shadow-black: rgb(0 0 0 / 14%);
$car-light-translucent-black: rgb(0 0 0 / 7.5%);
$car-medium-translucent-black: rgb(0 0 0 / 20%);
$car-heavy-translucent-black: rgb(0 0 0 / 40%);
$car-light-black: #2d2d2d;
$car-medium-gray: #888;
$car-yellow-file-remove-warning: #a0964a;

/* Maintain px here to match what is in the SEI Components. */
$car-header-height: 72px;
$car-footer-height: 40px;
$car-delete-button-line-height: 1.5rem;
$car-at-least-sm: 768px;
$car-xs-only: 767px;
$car-top-z-index: 99999;

/* Mix-ins */
@mixin light-form-labels() {
    font-weight: normal;
}

@mixin under-navbar() {
    z-index: 1029;
}

@mixin border($color, $size: 0.0625rem) {
    border: $size solid $color;
}

@mixin border-top($color, $size: 0.0625rem) {
    border-top: $size solid $color;
}

@mixin border-bottom($color, $size: 0.0625rem) {
    border-bottom: $size solid $color;
}

@mixin border-left($color, $size: 0.0625rem) {
    border-left: $size solid $color;
}

@mixin border-right($color, $size: 0.0625rem) {
    border-right: $size solid $color;
}

@mixin box-shadow($color) {
    box-shadow: 0 0.0625rem 0.0625rem $color inset;
}

@mixin transition-header() {
    color: $car-dark-blue;
    font-size: 0.875rem;
    font-weight: bold;
    background: linear-gradient(180deg, $car-white 0%, $car-snow-drift 100%);

    @include border-bottom($car-gray-indicator);

    padding: 0.375rem 1.125rem;
}

@mixin side-box() {
    @include border($car-gray-indicator);

    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

@mixin vertical-center() {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

$side-box-content-line-height: 3.25rem;
$app-header-height: 0;

