@import "colors";
/* stylelint-disable no-descending-specificity */
.wip-summary-right {
    .disabled {
        color: $charcoal4 !important;
        cursor: inherit !important;
    }
}

.wip-section {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-in-out;

    &.closed {
        border: none;
        height: 6rem;
        overflow: hidden;
    }

    &.open {
        height: auto;
        overflow: auto;
    }

    h5 {
        margin: 1rem 0 1rem 2rem;
    }

    .wip-header {
        background: $charcoal6;
        height: 6rem;
        box-sizing: border-box;
        position: relative;
        z-index: 20;

        .snipe {
            position: absolute;
            z-index: 100;
            width: 1rem;
            height: 1rem;
            top: 0;
            left: 0;

            &.done {
                border-top: 10px solid $sei-green;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid $sei-green;
            }

            &.alert {
                border-top: 10px solid $sei-orange;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid $sei-orange;
            }

            &.error {
                border-top: 10px solid $sei-red;
                border-right: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid $sei-red;
            }
        }

        h4 {
            width: 60%;
            float: left;
            padding-left: 2rem;
            line-height: 3em;
        }

        .wip-menu-icon {
            display: inline-block;
            position: relative;
            z-index: 20;
            float: right;
            text-align: center;
            height: 6rem;
            margin-right: 2rem;
            color: $brand-primary;
            cursor: pointer;

            i.fas {
                line-height: 3.5em;
                font-size: 1.6rem;
                vertical-align: baseline;
            }
        }

        .wip-menu {
            position: absolute;
            z-index: 250;
            top: 40px;
            right: -30px;
            width: 240px;
            color: $charcoal5;
            background: $white;
            height: auto;
            line-height: 30px;
            border-radius: 5px;
            box-sizing: border-box;
            box-shadow: 0 4px 10px rgb(0 0 0 / 25%);

            /* top pointer */
            i.fas {
                line-height: 1em;
                font-size: 1.5rem;
                vertical-align: baseline;
            }

            &::after {
                content: "";
                position: absolute;
                z-index: 10;
                top: -10px;
                left: 80%;
                width: 0;
                height: 0;
                border-bottom: 10px solid $white;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
            }

            & .closeit {
                height: 1.6rem;
                padding-top: 1rem;
                padding-right: 1rem;
                margin-bottom: 0.5rem;
                color: $charcoal5;
                text-align: right;
                font-size: 1.4rem;
                line-height: 1em;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    text-align: center;
                    font-size: 1.2rem;
                    line-height: 1em;
                    display: block;
                    margin: 0;
                    padding: 1rem;

                    &.warning {
                        background: rgb(210 30 10 / 10%);
                        padding: 1rem;
                        color: $charcoal1;
                        border: 2px solid $white;
                        -moz-border-radius-bottomright: 5px;
                        -moz-border-radius-bottomleft: 5px;
                        border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 5px;

                        h5 {
                            display: block;
                            width: auto;
                            float: none;
                            color: $sei-red;
                            text-align: center;
                        }

                        .primary {
                            margin: 1rem auto 0;

                            i {
                                color: $sei-red;
                            }
                        }
                    }
                }
            }
        }
    }

    .wip-summary {
        font-size: 1.4rem;
        color: $charcoal1;
        margin: 0;

        h5 {
            padding: 1rem 0 1rem 2rem;
            margin: 0 0 1rem;
            background-color: $white;
        }

        &.green {
            background-color: $sei-green-10;
        }

        .wip-summary-row {
            box-sizing: border-box;
            font-size: 1.4rem;
            color: $charcoal2;
            margin: 0;
            padding: 0.5rem 2rem 0.5rem 4.5rem;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            .wip-summary-left {
                width: 65%;
                text-align: left;

                input[type="radio"],
                input[type="checkbox"] {
                    display: inline-block;
                    vertical-align: text-top;
                    margin-left: -2.5rem;
                }

                p {
                    margin: 0.625rem 1rem;
                    font-size: 1.6rem;
                    color: $charcoal1;
                    text-align: left;
                    display: inline-block;
                    letter-spacing: 0.01em;
                }
            }

            .wip-summary-right {
                width: 32%;
                text-align: right;

                .wip-summary-icon {
                    display: inline-block;
                    font-size: 1.6rem;
                    float: right;
                    text-align: center;
                    margin: 0.625rem 0 0.625rem 2rem;
                    color: $brand-primary;
                    cursor: pointer;

                    i.fas {
                        line-height: 1.5em;
                        font-size: 1.6rem;
                        vertical-align: baseline;
                    }
                }

                div:not(:first-child) {
                    border-right: 1px solid $charcoal5;
                    padding-right: 2rem;
                }
            }
        }
    }
}

.tax-padding {
    padding-bottom: 0 !important;
}
