@import "colors";
@import "mixins";

.strategy-ribbon {
    text-align: left;
    position: relative;
    z-index: 20;
    border: 1px solid $charcoal5;
    display: block;
    box-sizing: border-box;
    margin: 0 auto 1rem;
    padding-top: 0.5rem;
    transition: box-shadow 1s ease-in-out;
    transition: border-color 0.5s ease-out;

    .pill.alert {
        position: relative;
        bottom: -0.5rem;
        left: -4rem;
    }

    .strategy-content,
    .strategy-drawer {
        padding: 0 1rem;

        .strategy-title {
            font-size: 1.4rem;
            color: $charcoal2;
            text-align: left;
            letter-spacing: 0.01em;
            line-height: 1.1em;
        }

        .strategy-description {
            font-size: 1.2rem;
            color: $charcoal2;
            line-height: 1.66em;
            margin-bottom: 1rem;
        }
    }

    .strategy-drawer {
        display: block;
        padding-top: 1rem;
        height: 0;
    }

    &.strategy-bb {
        border: 1px solid $brand-primary;
        box-shadow: 0 0 0 1px $brand-primary;
    }

    .strategy-hover {
        background: $brand-primary;
        padding: 2px;
        box-sizing: border-box;
        transition: opacity 0.2s ease-in-out;
        margin: 0 auto;
        text-align: left;
        position: absolute;
        z-index: 100;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }

    &.drawer-closed {
        cursor: auto;
        height: 9rem;
        overflow: hidden;
    }

    &.drawer-open {
        cursor: auto;
        height: auto;
        overflow: auto;
        border: 1px solid $brand-primary;
        box-shadow: 0 0 0 1px $brand-primary;

        .strategy-hover {
            opacity: 1;
            position: relative;
            z-index: 10;
            margin-top: -0.5rem;
            margin-bottom: 1rem;
        }

        .strategy-drawer {
            height: auto;
        }
    }

    .btn {
        &.sml {
            margin: 0.25rem 0 0.25rem 1rem;
        }
    }
}

.strategy-summary-error .pill.alert {
    &::before {
        border-top: 8px solid $sei-red;
        border-bottom: 0 solid $sei-red;
        top: 21px;
    }
}

.topnav-strategy-menu {
    overflow: hidden;
    background-color: $white;

    a {
        float: left;
        display: block;
        color: $charcoal1;
        text-align: center;
        padding: 1.5rem 0 0.5rem;
        text-decoration: none;
        border-bottom: 0.3rem solid $charcoal6;
    }

    a.disabled {
        color: $charcoal6;
        pointer-events: none;
        text-decoration: none;
    }

    a.active {
        border-bottom: 0.3rem solid $brand-primary;
    }
}

.strategy-ribbon-row {
    margin: 0 -3rem 0 -0;
}

sei-car-filter-strategy-risk-rank {
    margin: 0 0 0 -1.5rem;
}
