@import "colors";
@import "mixins";

#dashboard {
    padding-top: 4rem;
}

.modal-sales-entry {
    width: 96%;
    max-width: 640px;
    margin: 2rem auto;
    min-height: 29rem;

    .btn-row {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid $charcoal5;
        text-align: right;
    }
}

@media screen and (width >= 992px) {
    .modal-sales-entry {
        width: 80%;
        margin: 4rem auto;
    }
}

@media screen and (width >= 1200px) {
    .modal-sales-entry {
        width: 50%;
        margin: 6rem auto;
    }
}

.dash-info-row {
    align-items: flex-end;
    padding: 0 1rem;
}

.start-new-proposal {
    border: 2px dotted $brand-primary;
    padding: 2rem 6rem;
    color: $brand-primary;
    text-align: center;
    line-height: 1em;
    position: relative;
    display: inline-block;
}

.sei-advisor-block {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 1rem;
    background-color: $white;
    border: 1px solid $charcoal6;
    box-sizing: border-box;
    box-shadow: 0 6px 9px -6px rgb(0 0 0 / 30%);
    border-radius: 5px;
    font-size: 1rem;
    line-height: 1em;

    >div {
        display: inline-flex;
        text-align: left;
        line-height: 1.33em;
        word-break: keep-all;
        margin: 0 1.5rem;

        &::first-line {
            font-size: 8px;
        }
    }
}

.dash-totals {
    background-color: $sei-green-10;
    margin: 0;
    padding: 1rem;
    display: inline-flex;
    border-radius: 0.5rem;

    >div {
        display: inline-block;
        text-align: left;
        line-height: 1.33em;
        margin: 0.5rem;
        white-space: nowrap;

        &::first-line {
            font-weight: 700;
        }
    }

    div:first-child {
        margin-right: 2rem;
    }
}

.dash-details {
    padding: 1rem 0;
    border: 1px solid $charcoal5;
    box-sizing: border-box;

    &.row {
        margin: 0;
    }

    h3 {
        border-bottom: 1px solid $charcoal6;
        width: 99%;
    }

    .summary-group {
        margin: 1rem 0;

        .summary-label,
        .summary-item {
            display: block;
            padding: 0;
            margin: 0;
        }

        .summary-label {
            font-size: 1.4rem;
            font-weight: 400;
            color: $charcoal3;
        }

        .summary-item {
            font-size: 1.6rem;
            font-weight: 700;
            color: $charcoal2;
        }

        &.summary-columns {
            @include flx-row;
        }

        .summary-column {
            padding: 0;
            display: inline-block;

            h5 {
                line-height: 1em;
                margin: 0.5em auto 0;
            }

            h1 {
                color: $brand-primary;
                margin: 0 auto;
                line-height: 1.33em;
            }

            p {
                font-size: 1.2rem;
                margin: 0 auto;
                line-height: 1.33em;
                color: $charcoal2;
            }
        }
    }

    .tabs-plain {
        margin-bottom: 2rem;
    }

    .tab-plain {
        font-size: 1rem;
        font-weight: normal;
        color: $charcoal4;
        padding: 0.125rem 0.5rem;
    }

    #close-rates {
        .tabs-plain {
            margin-left: 32%;
            padding: 0;
            width: 68%;
        }

        .tab-bar {
            width: 100%;
        }
    }
}

.dashboard-row {
    padding: 0 15px;
    margin: 0 auto;
    text-align: left;

    @include flx-row;

    box-sizing: border-box;

    .dashboard-cell {
        display: inline-block;
        padding: 0.75rem 0.5rem;
        box-sizing: border-box;
        text-align: left;
        font-size: 1rem;
        line-height: 1em;
        font-weight: 400;
        margin: 0.5rem 0.1rem;
        color: $charcoal2;

        &.header {
            background-color: $charcoal6;
            text-align: center;
            color: $charcoal2;
            font-weight: 400;
            transition: all 0.2s ease-in-out;
            margin-top: 1rem;

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }

            &.sorted {
                font-weight: 700;
                color: $charcoal2;

                &.down,
                &.up {
                    &::after {
                        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 900;
                        float: right;
                        text-align: right;
                    }
                }

                &.down {
                    &::after {
                        content: "\f0d7";
                    }
                }

                &.up {
                    &::after {
                        content: "\f0d8";
                    }
                }
            }
        }
    }

    .dash-actions {
        span {
            color: $brand-primary;
            cursor: pointer;
            display: inline-block;
            padding: 0 0.75rem;
            border-right: 1px dotted $charcoal5;

            &:last-of-type {
                padding-right: 0;
                border-right: none;
            }

            &.disabled {
                color: $charcoal4;
                cursor: inherit;
            }
        }
    }
}

@media screen and (width >= 768px) {
    .dashboard-row {
        .dashboard-cell {
            font-size: 1.1rem;
            font-weight: 400;
        }
    }
}

@media screen and (width >= 992px) {
    .dash-details {
        .tab-plain {
            font-size: 1.1rem;
            padding: 0.125rem 0.75rem;
        }
    }

    .dashboard-row {
        .dashboard-cell {
            font-size: 1.2rem;
            letter-spacing: 0.01em;
        }
    }
}

@media screen and (width >= 1200px) {
    .dash-details {
        .tab-plain {
            font-size: 1.2rem;
        }
    }

    .dashboard-row {
        .dashboard-cell {
            font-size: 1.4rem;
        }
    }
}
