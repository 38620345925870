@import "dashboard";
@import "worksheet";
@import "hud";
@import "checklist";
@import "strategy";
@import "pills";
@import "risk-widget";
@import "presentation";
@import "colors";
@import "mixins";
@import "wip-checklist";
@import "car-custom";
@import "animate";
@import "fee-analysis";
@import "goal";
@import "questionnaire";
@import "ias-car";
@import "account-checklist";
@import "proposal-tax-transition";

sei-car-proposal-tax-transition-analysis,
sei-car-account-checklist,
car-wip-checklist-component,
sei-car-settings,
car-checklist,
car-mdc-proposal-component,
car-dashboard-component {
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
        display: block;
    }

    .main-content {
        padding: 0 1rem;
    }

    p {
        margin: 0.5rem auto;

        &.small {
            font-size: 1.44rem;
        }

        &.x-small {
            font-size: 1.2rem;
            line-height: 1.33em;
        }
    }

    .copy-small {
        font-size: 1.4rem;
        line-height: 1.44em;
    }

    .copy-xsmall {
        font-size: 1.2rem;
        line-height: 1.44em;
    }

    span.dlr {
        font-size: 0.75em;
        vertical-align: text-top;
    }

    .bg-tint {
        background-color: $sei-blue-10;
    }

    .clearfix {
        display: flow-root;
        zoom: 1;

        &::after {
            content: " ";
            display: block;
            font-size: 0;
            height: 0;
            clear: both;
            visibility: hidden;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-style: normal;
        word-wrap: break-word;
        line-height: 1.4em;
        margin: 0.5rem 0;
        letter-spacing: -0.005em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizelegibility;
    }

    h2,
    h6 {
        font-weight: 400;
        color: $charcoal1;
    }

    h1 {
        font-size: 2.1rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.6rem;
    }

    h4 {
        font-size: 1.4rem;
    }

    h5,
    h6 {
        font-size: 1.2rem;
    }

    .mrdc-page {
        h2 {
            font-weight: 700;
            color: $charcoal2;
        }
    }

    #main-content {
        padding-bottom: 2em;
    }

    @media screen and (width >= 992px) {
        h1 {
            font-size: 2.4rem;
            margin: 1rem 0;
        }

        h2,
        h3 {
            font-size: 1.8rem;
        }

        h4 {
            font-size: 1.8rem;
        }

        h5,
        h6 {
            font-size: 1.4rem;
        }

        p {
            margin: 0.75rem auto;

            &.small {
                font-size: 1.4rem;
                line-height: 1.44em;
            }

            &.x-small {
                font-size: 1.2rem;
                line-height: 1.44em;
            }
        }

        .copy-small {
            font-size: 1.4rem;
            line-height: 1.44em;
        }

        .copy-xsmall {
            font-size: 1.2rem;
            line-height: 1.44em;
        }

        #main-content {
            padding-bottom: 1rem;
        }
    }

    .text-left {
        text-align: left;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    .relative {
        position: relative;
    }

    .comment-type {
        color: $charcoal1;
        font-family: Consolas, "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", Monaco, "Courier New", monospace;
    }

    .comment-block {
        background-color: #f3f3f6;
        color: $charcoal1;
        border-left: 5px solid $charcoal5;
        box-sizing: border-box;
        padding: 1rem;
        font-family: Consolas, "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", Monaco, "Courier New", monospace;
        font-size: 1.4rem;
        line-height: 1.66em;
    }

    a {
        transition: text-decoration 0.15s ease-in-out;
    }

    a:link {
        color: $brand-primary;
        text-decoration: none;
    }

    a:hover,
    a:active {
        color: $brand-primary;
        text-decoration: underline;
    }

    *::selection {
        color: #fff;
        background: $sei-green;
    }

    *::selection:window-inactive {
        background: $charcoal6;
    }

    *:focus {
        outline: none;
    }

    .content-topic {
        position: relative;
        box-sizing: border-box;
        border-radius: 0.5rem;
        padding: 1rem 2rem;
        margin: 1.5rem auto;
    }

    .section-gap {
        margin-top: 3.1rem;
    }

    .group-gap {
        margin-top: 2rem;
    }

    .button-gap {
        margin-top: 1rem;
    }

    .gap {
        margin-bottom: 2rem;
    }

    .gap-12 {
        margin-bottom: 12rem;
    }

    .color-swatch {
        box-sizing: border-box;
        background-color: transparent;
        width: 100%;
        padding: 10px 5px 15px;
        border-radius: 1px;
        margin-bottom: 10px;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;

        p {
            margin: 2px auto;
            line-height: 1.1em;
            padding: 0;
        }

        :nth-child(3) {
            font-size: 1.2rem;
            line-height: 1.2em;
        }
    }

    @media screen and (width >= 992px) {
        .color-swatch {
            padding: 15px 10px 20px;
            border-radius: 2px;
            font-size: 1.6rem;
            font-weight: 700;
            text-shadow: 0 1px 1px rgb(0 0 0 / 30%);
            margin-bottom: 20px;
        }
    }

    /* ----------------- GRAPHING CONTROLS ------------------------ */
    .graph {
        .bar-h-group {
            display: block;
            padding: 0;
            margin: 0 auto;
            position: relative;
            z-index: 10;
        }

        .bar-h-label {
            width: 26%;
            font-size: 1.125rem;
            letter-spacing: 0.05em;
            line-height: 1em;
            text-rendering: optimizelegibility;
            font-weight: 400;
            vertical-align: middle;
            color: $charcoal3;
            text-align: right;
            display: inline-block;
            padding-right: 0.5rem;
            box-sizing: border-box;
            margin: 0.2em 0 0;
        }

        .bar-h-line {
            width: 72%;
            text-align: left;
            display: inline-block;
            box-sizing: border-box;
            position: relative;
            z-index: 20;
            margin: 0;
        }

        .bar-h-fill {
            text-align: left;
            display: inline-block;
            box-sizing: border-box;
            height: 5px;
            margin: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            &.green {
                background-color: $sei-green;
            }

            &.orange {
                background-color: $sei-orange;
            }
        }

        .bar-h-number {
            text-align: left;
            display: inline-block;
            font-size: 1rem;
            color: $charcoal1;
            margin: -0.2em 0 0 0.5em;
        }
    }

    /* -----------NAV TABS------------------ */
    .tab-container {
        .nav-tabs {
            list-style: none;
            background-color: $charcoal6;
            padding: 1rem 4rem 0;
            box-sizing: border-box;
            margin: 0 -4rem;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            .nav-item {
                list-style: none;
                display: inline-block;
                position: relative;
                background: $charcoal5;
                height: 3.2rem;
                text-align: center;
                color: $charcoal3;
                font-weight: 700;
                font-size: 1.4rem;
                line-height: 1em;
                letter-spacing: 0.005em;
                text-rendering: optimizelegibility;
                padding: 1.1rem 2rem;
                margin-right: 1.6rem;
                -moz-border-radius-topleft: 10px;
                -moz-border-radius-topright: 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;

                .navtabright {
                    position: absolute;
                    z-index: 10;
                    width: 3.6rem;
                    height: 3.6rem;
                    background: url("../stylesheets/images/tabs/tabcorner-right-gray.png") no-repeat top right;
                    display: inline-block;
                    top: 0;
                    right: -15px;
                }

                &.selected {
                    background: $white;
                    color: $charcoal2;

                    .navtabright {
                        background: url("../stylesheets/images/tabs/tabcorner-right-white.png") no-repeat top right;
                    }
                }
            }
        }
    }

    .tab-cap {
        background-color: $charcoal6;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-sizing: border-box;
        position: relative;
        z-index: 10;

        .tab-cap-right {
            width: 48%;
            position: absolute;
            z-index: 25;
            top: 1rem;
            right: 2rem;
        }
    }

    .tab-plain {
        text-align: center;
        font-weight: 700;
        color: $charcoal2;
        padding: 0.25rem 1.5rem;
        margin-bottom: 0;
        display: inline-block;
        cursor: pointer;

        &.active {
            color: $brand-primary;
            border-bottom: 2px solid $brand-primary;
            position: relative;
            z-index: 50;
        }
    }

    .tab-bar {
        margin-top: -2px;
        width: 100%;
        height: 0.2rem;
        background-color: $charcoal5;
        display: block;
        position: relative;
        z-index: 10;
    }

    /* -------------FORMS----------------- */
    button,
    input,
    optgroup,
    select,
    textarea {
        color: inherit;
        font: inherit;
        margin: 0;
    }

    button {
        overflow: visible;
    }

    button,
    select {
        text-transform: none;
    }

    button,
    html input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        appearance: button;
        cursor: pointer;
    }

    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    input {
        line-height: normal;
    }

    input[type="checkbox"],
    input[type="radio"] {
        box-sizing: border-box;
        padding: 0;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        height: auto;
    }

    input[type="search"] {
        appearance: textfield;
        box-sizing: border-box;
    }

    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration {
        appearance: none;
    }

    textarea {
        overflow: auto;
    }

    select.form-control {
        appearance: menulist-button;
        background-position: 96% 50%;
        background-repeat: no-repeat;
        background-size: 0.9rem;
    }

    input[type="text"],
    input[type="url"],
    input[type="tel"],
    input[type="number"],
    input[type="color"],
    input[type="email"],
    input[type="search"],
    select.form-control,
    select {
        border: $charcoal5 1px solid;
        background: $white;
        color: $charcoal2;
        line-height: normal;
        font-size: 1.4rem;
        font-weight: 400;
        font-style: normal;
        box-sizing: border-box;
        padding: 0.5em 0.7em;
        height: 3.4rem;
        width: 100%;
        transition: border 0.3s ease-in-out;

        &.alert {
            border: $sei-red 1px solid;
        }

        &:focus {
            outline: 0;
            border: 1px solid $brand-primary;
        }

        &.full-width {
            width: 100%;
        }
    }

    option {
        background: $white;
        color: $charcoal2;
        font-weight: 400;
        font-size: 1.4rem;

        &:focus {
            background-color: #fff;
            outline: none;
            border: none;
            box-shadow: none;
        }
    }

    @include placeholder($charcoal5);

    ::input-placeholder,
    ::placeholder {
        color: $charcoal4;
    }

    .form-row {
        display: block;

        @include flx-row;

        .form-item {
            position: relative;
            z-index: 20;
            display: block;
            margin: 0.5rem 1%;
            width: 100%;
        }
    }

    @media screen and (width >= 768px) {
        .form-row {
            margin: 1rem 0;

            @include flx-row;
        }

        .form-item {
            padding: 0;
            margin: 0;
            display: inline-block;
        }
    }

    .flex-row {
        display: flex;
        flex-flow: row nowrap;
        box-sizing: border-box;
        justify-content: space-between;

        &.vert-ctr {
            align-items: center;
        }

        .flex-left,
        .flex-right {
            display: block;
        }

        .flex-left {
            text-align: left;
        }

        .flex-right {
            text-align: right;
        }
    }

    .flx-sz {
        &.size-10 {
            @include flx-sz(9%);
        }

        &.size-15 {
            @include flx-sz(13.5%);
        }

        &.size-20 {
            @include flx-sz(18.5%);
        }

        &.size-25 {
            @include flx-sz(23.5%);
        }

        &.size-30 {
            @include flx-sz(28.5%);
        }

        &.size-35 {
            @include flx-sz(33.5%);
        }

        &.size-40 {
            @include flx-sz(38.5%);
        }

        &.size-45 {
            @include flx-sz(43.5%);
        }

        &.size-50 {
            @include flx-sz(48.5%);
        }

        &.size-60 {
            @include flx-sz(58.5%);
        }

        &.size-70 {
            @include flx-sz(68.5%);
        }

        &.size-75 {
            @include flx-sz(73.5%);
        }

        &.size-80 {
            @include flx-sz(78.5%);
        }

        &.size-90 {
            @include flx-sz(88.5%);
        }

        &.size-100 {
            @include flx-sz(100%);

            margin: 5px 0;
        }
    }

    .indent-1 {
        margin-left: 1rem;
    }

    .indent-2 {
        margin-left: 2rem;
    }

    .indent-3 {
        margin-left: 3rem;
    }

    .indent-4 {
        margin-left: 4rem;
    }

    @media screen and (width >= 992px) {
        .indent-1 {
            margin-left: 2rem;
        }

        .indent-2 {
            margin-left: 4rem;
        }

        .indent-3 {
            margin-left: 6rem;
        }

        .indent-4 {
            margin-left: 8rem;
        }
    }

    #show-more-stuff {
        .show-more-content {
            height: 3.6rem;
            overflow: hidden;
            transition: all 0.3s ease-in-out;

            &.openned {
                height: auto;
                overflow: auto;
            }
        }
    }

    #showtoggle {
        position: relative;
        height: 1em;
        background: $white;
        transition: all 0.2s ease-in-out;

        .showrule {
            background: $white;
            border-bottom: 2px solid $charcoal5;
            width: 100%;
            height: 0.5em;
            position: absolute;
            left: 0;
            bottom: 1em;

            .showtag {
                background: $white;
                font-size: 1.4rem;
                text-align: left;
                line-height: 1em;
                color: $brand-primary;
                padding: 1px 0.5em 1px 1px;
                position: absolute;
                top: -0.1em;
                left: 0;
                cursor: pointer;

                i.fas {
                    display: inline-block;
                    margin-right: 0.5rem;
                    vertical-align: inherit;
                }
            }
        }

        &.open {
            height: 2em;
            text-align: center;
            font-size: 1.2rem;
            color: $charcoal4;
        }
    }

    h2.section-title {
        margin: 2rem 0 -1rem;
    }

    h3.group-title,
    h4.group-title,
    h5.group-title,
    h6.group-title {
        margin: 2rem 0 -1rem;
        display: inline-block;
        line-height: 1em;
    }

    .title-bar {
        padding: 2rem 1rem;
        background-color: $charcoal6;

        input[type="text"] {
            background-color: rgb(255 255 255 / 50%);
            color: $charcoal2;
            font-size: 1.4rem;
            font-weight: 700;
            border: none;
            width: 60%;
        }
    }

    .row {
        &.title-bar {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .elastic-search {
        width: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 20;
        box-sizing: border-box;

        & .search-input {
            width: 60%;
            display: inline-block;
        }

        & .search-button {
            width: 38%;
            margin-left: 1.5%;
            text-align: right;
            display: inline-block;
        }
    }

    @media screen and (width >= 992px) {
        .elastic-search {
            & .search-input {
                width: 70%;
            }

            & .search-button {
                width: 28%;
                margin-left: 1%;
            }
        }
    }

    @media screen and (width >= 1200px) {
        .elastic-search {
            & .search-input {
                width: 76%;
            }

            & .search-button {
                width: 22%;
                margin-left: 1%;
            }
        }
    }

    /* PICK LIST */
    .pick-list-block {
        @include flx-row;
    }

    .pick-list-item {
        border: 1px solid $charcoal5;
        background: $white;
        color: $charcoal2;
        font-size: 1.4rem;
        text-align: left;
        box-sizing: border-box;
        padding: 1rem 2rem;
        user-select: none;
        position: relative;
        margin: 0 0 1rem;
        transition: color 0.1s ease-in-out, border 0.2s ease-in-out, box-shadow 0.2s ease-in-out, top 500ms ease-out;

        &:hover {
            border: 1px solid $brand-primary;
            color: $charcoal1;
            cursor: pointer;

            @include btn-hover-box-shadow;
        }

        &.selected {
            display: block;
            border: 2px solid $brand-primary;
        }

        &.hidden {
            display: none;
        }

        &.demo {
            position: absolute;
        }
    }

    .pick-list-change {
        position: absolute;
        text-align: right;
        right: 2rem;
        top: 1rem;
        color: $sei-purple;

        & a {
            transition: text-decoration 0.15s ease-in-out;
        }

        & a:link,
        a:visited {
            color: $sei-purple;
            text-decoration: none;
        }

        & a:hover,
        a:active {
            color: $sei-purple;
            text-decoration: underline;
        }

        display: none;
    }

    .gradient-standard {
        background: $charcoal5;
        background: linear-gradient(to top, $white 0%, $charcoal5 100%);
        background: linear-gradient(to top, $white 0%, $charcoal5 100%);
        background: linear-gradient(to bottom, $white 0%, $charcoal5 100%);
        filter: progid:dximagetransform.microsoft.gradient(startcolorstr="$white", endcolorstr="$charcoal5", gradienttype=0);
    }

    sei-modal-window {
        h4 {
            font-size: 1.4rem;
            color: #fff;
        }
    }
}

.fas.disabled,
.fas[disabled],
.disabled > .fas,
[disabled] > .fas {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

// Added to make bootstrap tooltip match the bootstrap popover styling which are used interchangeably throughout this project and IAS lib
// -----------------------------------------------------------------------------------------------------------------------------------------
.tooltip-top .tooltip-arrow {
    color: white;
    border-top-color: white !important;
    width: 1rem;
    bottom: 1px !important;
}

.tooltip-right .tooltip-arrow {
    color: white;
    border-right-color: white !important;
    left: 1px !important;
}

.tooltip-left .tooltip-arrow {
    color: white;
    border-left-color: white !important;
    right: 1px !important;
}

.tooltip-bottom .tooltip-arrow {
    color: white;
    border-bottom-color: white !important;
    width: 1rem;
    top: 1px !important;
}

.tooltip-inner {
    background-color: white;
    color: black;
    border: 1px solid rgb(0 0 0 / 20%);
    border-radius: 6px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

.tooltip.in {
    opacity: 1 !important;
}

// -----------------------------------------------------------------------------------------------------------------------------------------

.asset-allocation-breakup {
    min-width: fit-content;

    .tooltip-inner {
        max-width: 40rem;
        width: 27rem;
    }
}
