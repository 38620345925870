@mixin btn-box-shadow() {
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
}

@mixin btn-hover-box-shadow() {
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
}

@mixin flx-row() {
    display: flex;
    flex-flow: row nowrap;
}

@mixin placeholder($color) {
    ::input-placeholder {
        color: $color;
    }

    ::placeholder {
        color: $color;
    }
}

@mixin border-radius($radius) {
    border-radius: $radius;
}

@mixin flx-sz($wdt,) {
    flex-basis: calc($wdt);
    width: calc($wdt);
}
