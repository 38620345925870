@import "colors";

.goal-account-group {
    display: block;
    position: relative;
    z-index: 100;
    margin: 1rem auto 2rem;
    background-color: $white;
    border: 1px solid $charcoal5;

    i.fas {
        cursor: pointer;
    }

    .row {
        padding-top: 1rem;
    }

    label {
        display: inline-block;
        margin-bottom: 0.7rem;
    }

    .center-header {
        line-height: 2rem;
        margin-top: 1rem;
        width: 70%;
    }

    .padding-restore {
        padding: 0;
    }

    .goal-group-header {
        background-color: $charcoal6;
        padding: 1rem 2rem;
        box-sizing: border-box;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-bottom: 0;

        .flex-left {
            car-alert-popover {
                .pill.alert {
                    bottom: unset;
                }
            }
        }
    }

    .circle-icon {
        padding-left: 0.5rem;
        font-size: 1.3rem;
    }

    .select {
        color: $charcoal5;
    }

    .goal-group-header h4,
    .goal-group-header h5,
    .goal-group-header p {
        line-height: 1.2em;
        margin: 0;
        padding: 0;
    }

    .goal-group-header p {
        display: inline-block;
    }

    .goal-group-header i.fas {
        color: $brand-primary;
        line-height: 2em;
    }

    .account-ribbon {
        margin: 1rem 2rem;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-bottom: 1rem;
    }

    .account-ribbon h2,
    .account-ribbon h4,
    .account-ribbon h5,
    .account-ribbon i.fas {
        line-height: 1em;
        display: inline-block;
        vertical-align: middle;
    }

    .flex-right i.fas {
        margin-left: 1rem;
        color: $brand-primary;
    }

    .account-ribbon .flex-left i.fas {
        margin-right: 1rem;
        color: $charcoal4;
    }
}
