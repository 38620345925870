@import "colors";

.question-container {
    margin-top: 2rem;
    margin-bottom: 0.625rem;
}

.questionnaire-container {
    padding: 0 2rem 10rem;
}

.radio {
    label {
        line-height: 2.2rem !important;
    }
}

.box-container {
    @include border($charcoal5);

    margin-left: 0;
    margin-right: 0;
}

.img-responsive {
    display: inherit;
}
