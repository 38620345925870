sei-car-proposal-tax-transition-analysis {
    .right-modal-container {
        position: fixed;
        z-index: 1032;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 40%);
    }

    .right-modal-content {
        position: relative;
        float: right;
        background-color: $white;
        border: 0.0625rem solid $gray-lighter;
        width: 50%;
        height: 100%;

        @include flex-display;
        @include flex-direction(column);
        @include flex-wrap(nowrap);

        transition: width 0.3s ease-in-out;
        box-shadow: -0.3125rem 0.0625rem 1rem 0.0625rem rgb(45 45 45 / 40%);
    }

    .right-modal-body {
        flex-grow: 1;
        min-height: 2em;
        overflow-y: auto;
        padding: 2.5rem 2.5rem 0;
        border-bottom: 1.25rem solid $white;
    }

    .right-modal-footer {
        flex-shrink: 0;
        border-top: 0.0625rem solid $gray-darker;
        padding: 0.875rem 0.875rem 0.875rem 2.5rem;
        bottom: 0;
        min-height: 3.75rem;
        width: 100%;
        background: linear-gradient(180deg, $white 0%, $gray-lighter 100%);
    }

    .error-banner {
        margin-bottom: 1.5rem;
    }

    .row {
        .alert {
            margin-bottom: 1rem;
        }
    }

    .taxrate-warning {
        .continue-message {
            visibility: hidden;
        }

        .btn-sm {
            margin-right: 1rem !important;
            font-size: 1.4rem;
            padding: 5px 12px;
        }
    }

    label {
        font-size: 1.4rem;
        line-height: 1.4;
    }

    bs-tooltip-container {
        transform: translate3d(-5rem, -8rem, 0) !important;
    }
}
