@import "colors";

#overlay {
    background-color: $charcoal1;
    opacity: 0.8;
    backdrop-filter: blur(20px);
    position: fixed;
    z-index: 999;
    inset: 0;
}

#worksheet,
#checklist-wip {
    /* WIP styling located in wip-checklist.scss */
    width: 95%;
    background-color: $white;
    box-sizing: border-box;
    top: 0;
    min-height: 100%;

    #main-content {
        h1 {
            line-height: 1em;
            margin: 0;
        }
    }
}

#worksheet {
    right: 0;
    box-shadow: -5px 0 10px -5px rgb(0 0 0 / 33%);
}

#checklist-wip {
    left: 0;
}

.worksheet-footer {
    position: fixed;
    z-index: 1010;
    bottom: 0;
    right: 0;
    border-top: 1px solid $charcoal5;
    padding: 2rem;
    box-shadow: 0 -5px 10px -5px rgb(0 0 0 / 33%);

    .btn {
        font-size: 1.4rem;
    }

    .flex-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}

@media screen and (width <= 1200px) {
    #checklist-wip-right {
        display: none;
    }
}

@media screen and (width >= 768px) {
    #worksheet,
    #checklist-wip {
        width: 80%;
    }

    .worksheet-footer {
        width: 80%;
    }

    .worksheet-gap {
        padding: 5rem;
        display: block;
        background: transparent;
    }

    .main-content {
        padding: 0 2rem;
    }
}

@media screen and (width >= 1200px) {
    #worksheet,
    #checklist-wip {
        width: 50%;
    }

    #checklist-wip-right {
        width: 50%;
    }

    .worksheet-footer {
        width: 50%;
    }

    .main-content {
        padding: 0 4rem;
    }
}

.flex-row {
    &.fees {
        h2 {
            margin: 0;
            padding: 0;
            line-height: 1em;
        }

        p {
            margin: 0.5rem auto;
            line-height: 1em;
        }

        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $charcoal5;
    }
}

.fee-group {
    display: block;
    position: relative;
    z-index: 100;
    margin: 1rem auto 2rem;
    background-color: $white;
    border: 1px solid $charcoal5;

    .fee-group-header {
        background-color: $charcoal6;
        padding: 1rem 2rem;
        box-sizing: border-box;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        h4,
        h5,
        p {
            line-height: 1.2em;
            margin: 0;
            padding: 0;
        }

        p {
            display: inline-block;
        }

        margin-bottom: 1rem;

        i.fas {
            color: $brand-primary;
            line-height: 2em;
        }
    }

    .account-ribbon {
        margin: 1rem 2rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-bottom: 1rem;
        border-bottom: 1px solid $charcoal5;

        h2,
        h4,
        h5,
        i.fas {
            line-height: 1em;
            display: inline-block;
            vertical-align: middle;
        }

        .flex-left {
            i.fas {
                margin-right: 1rem;
                color: $charcoal4;
            }
        }

        .flex-right {
            i.fas {
                margin-left: 1rem;
                color: $brand-primary;
            }
        }
    }

    &.pad {
        padding: 1rem 2rem;
    }
}
