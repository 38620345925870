/* You can add global styles to this file, and also import other style files */
@import "./sei-car-variables";
@import "../../../node_modules/@sei/common-components-lib-ux/stylesheets/common-mixins/sei-flex-mixins";
@import "../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "../../../stylesheets/car-design";

$white: #fff;

@font-face {
    font-family: ProximaNova;
    src: url("./assets/fonts/ProximaNova-Regular.otf");
}

@font-face {
    font-family: ProximaNova;
    src: url("./assets/fonts/ProximaNova-RegularItalic.otf");
    font-style: italic;
}

@font-face {
    font-family: ProximaNova;
    src: url("./assets/fonts/ProximaNova-Bold.otf");
    font-weight: bold;
}

html {
    font-size: 10px;
}

.container {
    width: 98%;
    margin: 0 auto;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

bs-datepicker-container,
bs-daterangepicker-container {
    z-index: 2050 !important;
}

/* since Right Hand Modal has a higher z index to be displayed over dashboard
 this change is required for the es entity search pop up */
bs-dropdown-container {
    z-index: 200000 !important;
}

sei-dev-parms {
    position: absolute;
    top: 0;
    left: 50%;
    padding-top: 0.75rem;
}

/* Confirming these sizes for use of the variables in the rest of the application */
sei-header .navbar {
    min-height: $car-header-height;
    height: $car-header-height;
    min-width: 100%;
    width: 100%;
}

footer.footer {
    height: $car-footer-height;
}

/* Animation styles
   -------------------------------- */
.rotate-180 {
    transform: rotate(180deg);
}

/* Flexbox styles
   -------------------------------- */
.flex-display {
    @include flex-display;
}

.flex-display-justify-center {
    @include flex-display;

    justify-content: center;
}

.flex-display-align-center {
    @include flex-display;

    align-items: center;
}

.flex-display-align-baseline {
    @include flex-display;

    align-items: baseline;
}

.flex-display-align-flex-end {
    @include flex-display;

    align-items: flex-end;
}

.flex-display-space-between {
    @include flex-display;

    justify-content: space-between;
}

.flex-direction-column {
    @include flex-display;

    flex-direction: column;
}

.flex {
    display: flex;
}

.flex-align-center {
    align-items: center;
    display: flex;
}

.ml-auto {
    margin-left: auto;
}

.custom-checkbox-check {
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 500;

    div:first-of-type {
        float: left;
        margin-right: 0.5rem;
    }

    input {
        display: none;

        & + span {
            align-items: center;

            @include border($car-gray-indicator);

            border-radius: 50%;
            display: flex;
            height: 1rem;
            justify-content: center;
            width: 1rem;

            i {
                color: $car-light-blue;
                font-size: 0.625rem;
                line-height: 0.375rem;
            }
        }
    }

    label {
        cursor: pointer;
    }

    .label-content {
        float: right;

        span {
            display: block;
        }
    }
}

.input-width {
    width: 100%;
}

.subtitle-format-format {
    font-size: 1rem;
    margin-bottom: 0.625rem;
    color: $car-gray-footer-summary;
}

.font-size-header {
    font-size: 1.2rem;
    margin-bottom: 0.625rem;
    color: $car-black;
}

.margin-header {
    margin-left: 0.625rem;
}

.margin-qualified {
    margin-top: -0.625rem;
}

.withholding-margin {
    margin-left: -3.125rem;
}

@media screen and (width <= 1900px) and (width >= 1636px) {
    .withholding-margin {
        margin-left: -0.75rem;
    }
}

.radio-style-checked {
    color: $car-light-blue;
    font-size: 0.75rem;

    @include border($car-white);
}

.radio-style-circle {
    background-color: $car-white;
    color: $car-white;

    @include border($car-gray-indicator);
}

.label-normal {
    font-weight: normal;
}

.label-bold {
    font-weight: bold;
}

.list-input-radio {
    list-style: none;
    margin: 0;
    padding: 0;
}

.car-cursor-not-allowed {
    cursor: not-allowed;
}

.car-control-disabled {
    pointer-events: none;
    border-radius: 0;
    opacity: 0.5;
    color: $car-gray-footer-summary;
    padding-bottom: 0.0625rem;
    margin-left: 0 !important;
}

.car-optional-label-group {
    @include flex-display;

    align-items: baseline;
    justify-content: space-between;
}

.car-optional-label {
    flex: 1;
    text-align: right;
    font-size: 0.625rem;
    font-weight: 400;
    color: $car-light-gray-optional;
}

select:hover,
.car-input:hover {
    color: inherit !important;

    @include border($car-gray-input-group-addon-border !important);
}

select:focus,
.car-input:focus {
    @include border($car-gray-input-group-addon-border !important);

    box-shadow: 0 0.0625rem 0.0625rem $car-light-translucent-black inset !important;
}

.vertical-align {
    display: flex;
    align-items: center;
}

.horizontal-align {
    display: flex;
    justify-content: center;
}

.mrdc-header {
    h2 {
        margin-top: 5px;
    }
}

.car-display-none {
    display: none;
}

.car-margin-top {
    margin-top: 15px;
}

.car-border-bottom {
    border-bottom: 0.0625rem solid $car-gray-indicator;
    padding-bottom: 0.625rem;
}

.car-border-bottom-no-padding {
    border-bottom: 0.0625rem solid $car-gray-indicator;
}

.car-bottom-padding {
    padding-bottom: 0.625rem !important;
}

.car-no-padding {
    padding: 0 !important;
    margin: 0 !important;
}

.car-no-bottom-padding {
    padding-bottom: 0 !important;
}

.car-no-right-padding {
    padding-right: 0 !important;
}

.car-pull-text-right {
    text-align: right;
}

.car-pull-text-left {
    text-align: left;
}

.car-disabled {
    opacity: 0.75;
    background: $car-white;
}

.car-panel {
    border-radius: 0;
}

.car-panel-heading {
    background-image: linear-gradient(to bottom, $car-white, $car-light-gray);
    padding-top: 0.5rem;
    padding-bottom: 0.375rem;
    border-bottom: none;
}

.car-panel-separator {
    border-bottom: 0.0625rem solid $car-gray-indicator;
}

.car-panel-body {
    padding-top: 0;
    padding-bottom: 0;
    color: $car-gray-footer-summary;
}

.car-fa-orange {
    color: $car-orange-indicator;
    opacity: 0.85;
}

.car-fa-lightblue {
    color: $car-light-blue;
    opacity: 0.85;
}

.check-bubble-indicator {
    color: $car-light-gray;
}

.complete-bubble-indicator {
    color: $car-green-indicator;
}

.inprogress-bubble-indicator {
    color: $car-yellow-indicator;
}

.car-danger-color,
.failed-bubble-indicator {
    color: $car-dark-red;
}

.car-action-color,
.authorization-bubble-indicator {
    color: $car-light-blue !important;
}

.car-margin-subitem {
    margin-left: 1.25rem;
}

.car-action-label {
    color: $car-light-blue;
    cursor: pointer;
}

.section-divisor {
    @include border($car-gray-indicator);
}

.car-default-cursor {
    cursor: default !important;
}

.car-pointer {
    cursor: pointer;
}

.car-action-sei-middle-blue {
    color: $car-blue !important;
}

.car-right-modal-container {
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    /* stylelint-disable-next-line */
    background-color: rgba(0, 0, 0, 0.4);
}

.car-right-modal-content {
    position: relative;
    float: right;
    background-color: #fff;
    border: 1px solid #888;
    width: 80%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    transition: width 0.3s ease-in-out;

    @media (width >= 1024px) {
        width: 50%;
    }
}

.car-right-modal-body {
    flex-grow: 1;
    min-height: 2em;
    overflow-y: auto;
    padding: 40px;
    padding-bottom: 110px;
}

.car-right-modal-footer {
    flex-shrink: 0;
    border-top: 1px solid #5a5a5a;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 14px;
    bottom: 0;
    height: 60px;
    width: 100%;
    background: linear-gradient(180deg, #fff 0%, #e7e7e6 100%);
}

.car-centered-modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $car-medium-translucent-black;
}

.car-centered-modal-panel-heading {
    background-color: $car-light-blue;
}

.car-centered-modal-header {
    font-size: 0.875rem;
    font-weight: bold;
    color: $car-light-gray;
    padding-left: 1rem;
}

.car-centered-modal-content {
    display: flex;
    flex-flow: column nowrap;
    width: 70%;
    height: 80%;
    background-color: $car-white;
}

.car-centered-modal-body {
    flex-grow: 1;
    min-height: 2em;
    padding: 1.25em;
    padding-bottom: 2.5em;
    overflow-y: auto;
}

.car-centered-modal-footer {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-right: 0.875rem;
    height: 3.875rem;
    background-color: $car-light-gray;
}

.car-option-container {
    padding-left: 0.625rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include border($car-gray-indicator);

    cursor: pointer;
    margin: 0.5rem inherit;
}

.car-option-container-selected {
    @include border($car-light-blue);

    cursor: auto;
}

.car-option-container-text-selected {
    color: $car-light-blue;
}

.car-option-container-selected a {
    text-decoration: none;
}

.car-option-radio-container {
    @include border($car-gray-indicator);

    border-radius: 0.625rem;
    margin-right: 0.625rem;
}

.car-option-radio-icon {
    color: transparent;
}

.car-option-checked-icon {
    color: $car-light-blue;
    font-size: 1.5em;
}

.car-option-action {
    text-align: right;
}

.wip-summary-label {
    color: $car-gray-progress-line-indicator;
    font-size: 0.875rem;
}

.wip-summary-text {
    color: $car-dark-gray;
    font-size: 1rem;
}

.wip-summary-row {
    padding-bottom: 5px;
}

.delegation-container {
    padding-bottom: 0.625rem;
    background-color: $car-light-gray;
    border-radius: 0.3rem;
    padding-top: 0.625rem;
    margin-bottom: 1rem;
}

.brick-asset-header-column {
    background-color: $car-color-secondary-gray;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;

    @include border($car-white);
}

.asset-row-header-columns {
    margin: 1.25rem 0 0.625rem !important;
}

.icon-trash {
    color: $car-light-gray-optional;
    cursor: pointer;
}

.icon-trash:hover {
    color: $car-blue;
}

.car-bold {
    font-weight: bold !important;
}

.car-table-header {
    font-size: 1rem;
    font-weight: bold;
    color: $car-dark-blue;
}

.car-sm-table-header {
    font-size: 0.75rem;
    font-weight: bold;
    color: $car-dark-blue;
}

.car-main-body-copy {
    font-size: 1rem;
    color: $car-gray-footer-summary;
}

.car-main-sm-body-copy {
    font-size: 0.75rem;
    color: $car-gray-footer-summary;
}

.car-main-xs-body-copy {
    font-size: 0.625rem;
    color: $car-gray-footer-summary;
}

.car-numbers-copy {
    font-size: 1.2rem;
    color: $car-dark-gray;
}

.pdf-icon {
    color: $car-light-blue;
}

.location-icon {
    color: $car-gray-indicator;
}

.delete-account-popover {
    width: 15rem !important;
    padding: 0.2rem !important;
    border: none;
    background-color: $car-white !important;
    border-radius: 0.25rem !important;
    box-shadow: 0 0.25rem 0.625rem 0 $car-medium-translucent-black !important;

    @include under-navbar;

    .arrow {
        border-bottom-color: $car-medium-translucent-black !important;
    }

    .arrow::after {
        border-bottom-color: $car-white !important;
    }

    &.car-offset-bottom-right {
        /* stylelint-disable-next-line length-zero-no-unit */
        right: 0px !important;
        left: unset !important;

        .arrow,
        .arrow::after {
            left: 90% !important;
        }

        &-panel {
            padding-right: 0.9375rem !important;
            padding-left: 0.9375rem !important;
        }
    }

    .popover-content {
        /* stylelint-disable-next-line length-zero-no-unit */
        padding: 0px !important;
        background-color: $car-white !important;
    }

    .delete-account-panel {
        background-color: $car-light-red;
        border-radius: 0.25rem !important;
        text-align: center;

        h3 {
            color: $car-dark-red;
            font-weight: bold;
        }

        .delete-text {
            color: $car-dark-gray;
        }

        .trash-button-icon {
            color: $car-light-pink;
        }
    }
}

wj-input-date.wj-control.wj-inputdate,
wj-input-number.wj-control.wj-inputnumber {
    /* Applying the .form-control styles from advisors1.css to this control */
    width: 100%;
    height: 34px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    /* stylelint-disable-next-line */
    box-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.075) inset;
    color: #5a5a5a;

    input {
        text-align: right;
    }

    .wj-glyph-down {
        border: none;
        font-size: 12px;

        &::before {
            /* copied from common styles in common-angular-lib-ux */
            /* stylelint-disable-next-line */
            font-family: "Font Awesome 6 Pro";
            content: "\f073";
            font-weight: 400;
            color: #333;
        }
    }
}

$font-redtail: "redtail";

@font-face {
    font-family: $font-redtail;
    src: url("./assets/fonts/redtail.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon-redtail::before {
    content: "\61";
    color: #b2292e;

    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-redtail !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

sei-dashboard-tile .panel-heading-title {
    width: min-content;
}

body:has(sei-car-proposal-tax-transition-analysis-current-account) {
    .modal-backdrop {
        position: inherit;
    }
}
