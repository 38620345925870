car-wip-checklist-component,
sei-car-account-checklist,
sei-car-proposal-tax-transition-analysis,
sei-car-settings,
car-checklist,
car-mdc-proposal-component,
car-dashboard-component {
    label {
        display: block;
        font-size: 12px;
        line-height: 1em;
        margin: 0 0 2px;
        color: $charcoal3;
    }

    .tab-cap {
        padding: 4rem 0 0;
    }

    .no-padding-left {
        padding-left: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 700;
        color: $charcoal2;
        letter-spacing: 0.01em;
    }
}

sei-ias-investment-selection {
    label {
        display: unset;
        font-size: unset;
        line-height: unset;
        margin: unset;
        color: unset;
    }

    .strategy-title {
        font-weight: bold;
    }
}

sei-ias-invest-tools-menu {
    .tab-cap {
        padding: 0.5rem 0 0;
    }
}

sei-ias-asset-allocation-metrics {
    h3,
    h5 {
        color: $white;
    }
}
