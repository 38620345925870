@import "colors";

.pill {
    border-radius: 5px;
    padding: 0.325rem 0.625rem;
    font-size: 11px;
    text-align: center;
    line-height: 1em;
    letter-spacing: 0.01em;
    margin: 0 1rem 0 0;
    box-sizing: border-box;
    position: relative;
    vertical-align: text-bottom;
    display: inline-block;

    &.primary {
        background-color: $white;
        border: 1px solid $charcoal2;
        color: $charcoal2;
    }

    &.new {
        background-color: $sei-yellow-50;
        border: 1px solid $sei-yellow-50;
        color: $charcoal1;
    }

    &.used {
        background-color: $charcoal6;
        border: 1px solid $charcoal6;
        color: $charcoal1;
    }

    &.existing {
        background-color: $sei-blue-50;
        border: 1px solid $sei-blue-50;
        color: $charcoal1;
    }

    &.maintain {
        background-color: $sei-blue-50;
        border: 1px solid $sei-blue-50;
        color: $charcoal1;
    }

    &.custom {
        background-color: $sei-purple-50;
        border: 1px solid $sei-purple-50;
        color: $charcoal1;
        margin-left: 0.5rem;
    }

    &.full-transition {
        background-color: $sei-purple-50;
        border: 1px solid $sei-purple-50;
        color: $charcoal1;
        margin-left: 0.5rem;
    }

    &.partial-transition {
        background-color: $sei-purple-50;
        border: 1px solid $sei-purple-50;
        color: $charcoal1;
        margin-left: 0.5rem;
    }

    &.partial-transfer {
        background-color: $sei-purple-50;
        border: 1px solid $sei-purple-50;
        color: $charcoal1;
        margin-left: 0.5rem;
    }

    &.na {
        background: $charcoal5;
        border: 1px solid $charcoal1;
        color: $charcoal1;
    }

    &.low {
        background-color: $white;
        border: 1px solid $sei-green;
        color: $sei-green;
    }

    &.medium {
        background-color: $white;
        border: 1px solid $sei-orange;
        color: $sei-orange;
    }

    &.high {
        background-color: $white;
        border: 1px solid $sei-red;
        color: $sei-red;
    }

    &.alert {
        background-color: $sei-red;
        color: $white;
        white-space: nowrap;
        padding: 0.5rem 0.75rem;
        position: absolute;
        z-index: 500;
        bottom: -2.25rem;
        left: 30%;
        box-shadow: 0 6px 8px -4px rgb(0 0 0 / 20%);

        &::before {
            content: "";
            position: absolute;
            z-index: 10;
            top: -5px;
            left: 60%;
            width: 0;
            height: 0;
            border-bottom: 8px solid $sei-red;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
}
