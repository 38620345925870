@import "colors";

.fee-account-group {
    display: block;
    position: relative;
    z-index: 100;
    margin: 1rem auto 2rem;
    background-color: $white;
    border: 1px solid $charcoal5;

    i.fas {
        cursor: pointer;
    }

    .dropdown-webkit-appearance {
        appearance: menulist-button;
    }

    .drag-box {
        cursor: move;
    }

    .cdk-drag-placeholder {
        opacity: 0.45;
    }

    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .account-list .cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .fee-group-header {
        background-color: $charcoal6;
        padding: 1rem 2rem;
        box-sizing: border-box;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-bottom: 0;
    }

    .fee-group-header h4,
    .fee-group-header h5,
    .fee-group-header p {
        line-height: 1.2em;
        margin: 0;
        padding: 0;
    }

    h2 {
        font-weight: 400;
        color: $charcoal1;
        font-size: 1.8rem;
    }

    .fee-group-header p {
        display: inline-block;
    }

    .fee-group-header i.fas {
        color: $brand-primary;
        line-height: 2em;
    }

    .account-ribbon {
        margin: 1rem 2rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-bottom: 1rem;
    }

    .account-ribbon-border {
        border-bottom: 1px solid $charcoal5;
    }

    .account-ribbon h2,
    .account-ribbon h4,
    .account-ribbon h5,
    .account-ribbon i.fas {
        line-height: 1em;
        display: inline-block;
        vertical-align: middle;
    }

    .account-ribbon .flex-left {
        overflow-wrap: break-word;
        text-align: left !important;
        display: inline-block;
    }

    .left-component {
        text-align: center;
        flex-flow: row nowrap;
        justify-content: left;
        display: flex;
        inline-size: 50%;
    }

    .account-ribbon .left-component .menu-caret {
        display: inline-block;
        padding-right: 1rem;
    }

    .account-ribbon .left-component .menu-caret .fas {
        color: $charcoal4;
    }

    .flex-right {
        flex-flow: row nowrap;
    }

    .account-ribbon .flex-right i.fas {
        margin-left: 1rem;
        color: $brand-primary;
    }

    .pad {
        padding: 1rem 2rem;
    }

    .default {
        border-top: 10px solid $sei-dark-purple;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid $sei-dark-purple;
    }

    .snipe {
        position: absolute;
        z-index: 100;
        width: 1rem;
        height: 1rem;
        top: 2px;
        left: 2px;
        border-radius: 2px;
    }

    .fee-tier {
        background-color: $charcoal6;
        border-radius: 0.5rem;

        .fee-tier-ribbon {
            width: 25%;
        }

        .account-ribbon {
            padding-top: 0;
        }

        .margin-right {
            margin-right: 2.2rem;
        }

        .padding-left {
            padding-left: 2rem;
        }

        .padding-left-5 {
            padding-left: 5rem;
        }

        .padding-top {
            padding-top: 0.5rem;
        }
    }
}

wj-input-number.wj-control.wj-inputnumber input,
.wj-input-date.wj-control.wj-inputdate input {
    text-align: left;
}

.fee-schedule-fee-rate {
    border-left: 0.1rem solid $sei-dark-blue;
    border-right: 0.1rem solid $sei-dark-blue;

    .section-margin {
        margin: 0 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .tier-section-margin {
        margin: 0 1rem;
        padding-bottom: 1rem;
    }

    .subhead {
        font-weight: 700;
        color: $charcoal2;
        letter-spacing: 0.01em;
    }

    .input-fee-12 {
        width: 12rem !important;
    }

    .input-fee {
        width: 60%;
    }

    .tier-margin-right {
        margin-right: 2rem;
    }

    label {
        font-size: 1.6rem;
        font-weight: normal;
    }

    .pill.alert {
        position: absolute;
        left: -10rem;
        bottom: -3.5rem;
    }
}

.tier-margin-left {
    margin-left: 1rem;
}

.fee-group-account-detail {
    border-bottom: 1px solid $charcoal5;

    .header p {
        text-decoration: underline;
    }

    .subhead {
        font-weight: 700;
        color: $charcoal2;
        letter-spacing: 0.01em;
    }

    .box {
        border-top: 1px solid $charcoal5;
        float: right;
        margin: 1rem 2rem;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-bottom: 1rem;
    }

    .box p {
        float: right;
    }
}

.fee-group-ribbon {
    .strategy-ribbon {
        background: $brand-primary;
        padding: 1rem;
        margin: 1rem 0 0;
    }
}

.fee-wrapper {
    margin-bottom: 15rem;
}

.fee-header {
    padding: 1rem 2rem 1rem 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 0;
}
