@import "fileupload";

/* action confirmation popover */
.action-tooltip-popover {
    max-width: 28rem !important;
    padding: 0.3rem !important;
    border: none;
    background-color: $white !important;
    border-radius: 0.4rem !important;
    box-shadow: 0 0.4rem 1rem 0 rgb(0 0 0 / 20%) !important;

    .arrow,
    .arrow::after {
        border-bottom-color: $white !important;
    }

    .popover-content {
        padding: 0 !important;
        background-color: $white !important;
    }

    .delete-tooltip-panel {
        background-color: $sei-light-red;
        border-radius: 0.4rem !important;
        text-align: center;

        h3 {
            color: $sei-dark-red;
            font-weight: bold;
        }

        .delete-text {
            color: $charcoal1;
            padding-left: 4rem;
            padding-right: 4rem;
        }

        .trash-button-icon {
            color: $sei-light-pink;
        }

        .single-button {
            margin-right: 1rem;
        }
    }

    .info-tooltip-panel {
        background-color: $sei-light-blue;
        border-radius: 0.4rem !important;
        text-align: center;

        h3 {
            color: $sei-dark-blue;
            font-weight: bold;
        }

        .copy-text {
            color: $charcoal1;
            padding-top: 1rem;
        }

        .copy-button-icon {
            color: $brand-primary;
        }
    }

    .options-tooltip-panel {
        padding: 0 1rem;

        .option {
            cursor: pointer;
            color: $charcoal1;
        }

        .disabled {
            pointer-events: none;

            h2 {
                color: $charcoal4;
            }
        }
    }
}

.delete-icon {
    margin-top: 1rem;
}

.status-indicator {
    text-align: center;
    padding-top: 0.5rem;

    .section-disabled {
        opacity: 0.5;
        background: $white;
    }

    .no-padding {
        margin: 0 !important;
    }

    .status-icon {
        color: $charcoal4;
        padding: 0 !important;
        margin: 0 !important;
    }

    .fa-check-circle {
        color: $sei-green;
    }
}

.strategy-drawer-item-label {
    margin-top: 0.5rem;
    width: 9%;
}

footer {
    z-index: 1000;
    position: fixed;
}

@media screen and (width >= 76.8rem) {
    .form-row {
        margin: 1rem 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .form-item {
        padding: 0;
        margin: 0;
        display: inline-block;

        &.size-10 {
            width: 9%;
        }

        &.size-15 {
            width: 13.5%;
        }

        &.size-20 {
            width: 18.5%;
        }

        &.size-25 {
            width: 23.5%;
        }

        &.size-30 {
            width: 28.5%;
        }

        &.size-35 {
            width: 33.5%;
        }

        &.size-40 {
            width: 38.5%;
        }

        &.size-45 {
            width: 43.5%;
        }

        &.size-50 {
            width: 48.5%;
        }

        &.size-60 {
            width: 58.5%;
        }

        &.size-70 {
            width: 68.5%;
        }

        &.size-75 {
            width: 73.5%;
        }

        &.size-80 {
            width: 78.5%;
        }

        &.size-90 {
            width: 88.5%;
        }

        &.size-100 {
            width: 100%;
        }
    }
}

select.form-control,
.custom-select-control {
    appearance: none;
    background-position: 96% 50%;
    background-repeat: no-repeat;
    background-size: 0.9rem;
}

.toggle-row-right {
    display: contents !important;
}

.popover {
    z-index: 2001 !important;
}

.car-main-sm-body-copy {
    font-size: 1.2rem !important;
}

.image-modal-panel {
    .image-modal-container {
        position: fixed;
        z-index: 10000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 40%);
    }

    .image-modal-image {
        margin-top: -2.5rem;
        text-align: center;
    }

    .image-modal-content {
        position: relative;
        background-color: $white;
        width: 50%;
        height: 25%;
        left: 25%;
        top: 5%;
    }

    .image-modal-body {
        min-height: 2em;
        background-color: $white;
        padding-bottom: 2.5rem;
    }

    .image-modal-footer {
        flex-shrink: 0;
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        padding-right: 1.4rem;
        bottom: 0;
        height: 6rem;
        width: 100%;
        background-color: $charcoal6;
    }

    .image-header {
        font-size: 1.4rem;
        font-weight: bold;
        color: $charcoal6;
        padding-left: 1.7rem;
    }

    .image-panel-heading {
        background-color: $brand-primary;
        padding-top: 0.1rem;
        padding-bottom: 0.4rem;
    }

    .icon-x {
        top: -5.5rem;
        right: -1%;
        position: relative;
        color: $white;
        background-color: $brand-primary;
        border-radius: 3.6rem;
    }
}

.error {
    &-icon {
        color: $sei-red;
    }
}

.generic-error-notification {
    label {
        font-weight: normal;
        margin: 0;
    }

    h1 {
        padding: 0;
        margin: 0;
    }

    .error {
        &-container {
            border: 0.1rem solid $sei-red;
            border-top: 0.5rem solid $sei-red;
            background-color: rgba($sei-red, 8%);
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            width: 100%;
            padding: 0.5rem;
        }

        &-icon {
            color: $sei-red;
            font-size: 2.5rem;
            margin: 0.5rem;
            padding: 0.5rem;
        }

        &-text {
            display: flex;
            align-content: center;
            flex-direction: column;
            margin: 0;
            padding: 0;
        }
    }
}

.car-action-label {
    font-size: 2rem;
}

.start-new-proposal {
    cursor: pointer;
}

.truncate {
    width: 25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-sales-entry {
    width: 96%;
    max-width: 64rem;
    margin: 2rem auto;
    min-height: 29rem;

    .btn-row {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 0.1rem solid #d2d2d2;
        text-align: right;
    }
}

.form-inline .form-control {
    width: fill-available;
}

.car-modal-container {
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 40%);
}

.car-modal-content {
    background-color: $white;
    border: 1px solid $charcoal3;
    width: 50%;
    height: 50%;
    display: flex;
    flex-flow: column nowrap;
    transition: width 0.3s ease-in-out;
    transform: translate(50%, 50%);
}

.car-modal-body {
    flex-grow: 1;
    min-height: 2em;
    overflow-y: auto;
    padding: 2.5rem 2.5rem 0;
    border-bottom: 1.25rem solid $white;
}

.wip-section-footer {
    .row {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .format-button {
        margin-right: 15px;
    }
}

.typeahead {
    .search-results {
        border: solid;
        border-color: $charcoal5;
        border-width: thin;
    }

    .search-result-item {
        display: table-cell;
    }

    .search-result-item-firstrow {
        color: $brand-primary;
        display: table-row;
    }

    .search-result-item-row {
        color: $brand-primary;
        margin: 0.2rem;
        display: table-row;
    }

    .search-result-item-row:hover {
        background-color: $brand-primary;
        color: $white;
        cursor: pointer;
    }

    .pt5 {
        padding-top: 0.5rem;
    }

    .border-gray {
        border: 0.1rem solid $charcoal5;
    }

    .border-blue {
        border: 0.1rem solid $brand-primary;
    }

    .border-radius {
        border-radius: 1rem;
    }

    .label-results {
        color: $brand-primary;
        margin: 0.5rem;
    }

    .background-dark {
        background: $charcoal3;
        color: $white;
    }

    .form-search-control {
        display: block;
        padding: 0.5rem;
    }

    .manual-asset-box {
        padding: 0.5rem;
    }

    .input-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .input-total-purchase {
        width: 100%;
        border: 0;
    }

    .proposal-input {
        width: 100% !important;
    }
}

.fee-width-45 {
    width: 45% !important;
}

.loading-spinner-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.75;

    div.loading-spinner div.hold-spinner {
        text-align: center;
    }

    span.spinner {
        animation: spin 1250ms linear infinite;
        width: 3rem;
        height: 3rem;
        display: inline-block;
        color: $brand-primary;
        border: 0.25em solid currentcolor;
        border-right-color: transparent;
        border-radius: 50%;
    }
}

.questionnaire-container {
    padding: 4rem 4rem 10rem;
}

.sort-by-background {
    padding: 1rem;
    margin-left: 0;
    margin-bottom: 0.6rem;
    border-radius: 1rem 1rem 0 0;
    background-color: $charcoal6;

    cdk-virtual-scroll-viewport {
        height: 32rem;

        car-strategy-ribbon {
            height: 9rem;
        }

        &::-webkit-scrollbar {
            width: 1em;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px $charcoal1;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $charcoal3;
        }
    }
}

.account-panel-title {
    margin: 1.5rem 0 1rem;
}

.set-right {
    text-align: right;
}

.pull-padding {
    padding-right: 20%;
}

.no-row-padding {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.center {
    text-align: center;
}

#presentation-preview {
    position: fixed;
}

.btn.add-client-custom-row {
    color: $brand-primary;
    background: white;
    border: none;
    box-shadow: none;
    vertical-align: bottom;
    padding-left: 0;
    padding-top: 2rem;
}

i.fas.fa-trash-alt {
    cursor: pointer;

    &:hover {
        color: $brand-primary;
    }
}

.strategy-ribbon-footer {
    position: relative;
    z-index: 20;
    border-top: 1px solid $charcoal5;
    box-sizing: border-box;
    padding: 1rem;
}

sei-car-preview i.fa-chevron-right {
    padding-left: 0.5em;
}

sei-ias-investment-selection > div.row:last-child {
    padding-bottom: 13rem;
}
