/* SEI COLORS */

$sei-dark-green: #00692d;
$sei-teal: #13bfb1;
$sei-dark-teal: #0b6d65;
$sei-blue: #037ea6;
$sei-green: #66953d;
$sei-dark-blue: #173b6b;
$sei-dark-purple: #580c41;
$sei-dark-red: #91140f;
$sei-purple: #8e0c71;
$sei-orange: #ff8a00;
$sei-yellow: #efd900;
$sei-dark-orange: #f0500a;
$sei-red: #d21e05;
$sei-pink: #e60046;
$sei-light-green: #c3cd19;
$sei-light-red: #f9cccc;
$sei-light-pink: #e94646;
$sei-light-blue: #d4e8fa;
$charcoal1: #2d2d2d;
$charcoal2: #5a5a5a;
$charcoal3: #787878;
$charcoal4: #a0a0a0;
$charcoal5: #d2d2d2;
$charcoal6: #f0f0f0;
$white: #fff;
$black: #000;
$brand-primary: #254a5d;
$gray-lighter: $charcoal6;
$gray-darker: $charcoal3;
$sei-error-red: #a94442;

/* TINTS */
$sei-blue-10: rgb(3 126 166 / 8%);
$sei-blue-50: rgb(3 126 166 / 50%);
$sei-teal-50: rgb(19 191 177 / 50%);
$sei-green-10: rgb(102 149 61 / 10%);
$sei-green-50: rgb(102 149 61 / 50%);
$sei-dark-blue-50: rgb(23 59 107 / 50%);
$sei-purple-50: rgb(142 12 113 / 50%);
$sei-yellow-50: rgb(239 217 0 / 50%);
$sei-orange-50: rgb(255 138 0 / 50%);
$sei-red-50: rgb(210 30 5 / 50%);
