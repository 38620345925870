@import "colors";
@import "mixins";
/* stylelint-disable no-descending-specificity */
.us-letter {
    /* fixed size 8.5 x 11 format */
    background-color: $white;
    width: 552.7pt;
    max-width: 552.7pt;
    height: 703.2pt;
    margin: 0;
    position: relative;
    box-sizing: border-box;
    left: 0;
    z-index: 20;
    font-family: Arial, sans-serif;
    font-size: 10pt;
    line-height: 1.33em;
    color: $charcoal1;
    text-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.letter-margins {
        /* adds print safe margins to letter */
        padding: 48pt;
    }

    &.extended {
        /* makes one long page just in case needed */
        height: auto !important;
        overflow: visible;
    }

    .print-row {
        display: block;
        box-sizing: border-box;
        align-items: normal;
        margin: 0 auto;
        width: 100%;

        .print-col {
            display: inline-block;
            margin-right: 5pt;
            vertical-align: top;
        }

        div.print-col:last-child {
            margin-right: 0;
        }
    }

    p {
        margin: 8pt 0;

        &.footnote {
            color: $charcoal4;
            font-style: italic;
        }
    }

    h1,
    h2 {
        margin: auto;
        color: $sei-dark-blue;
        font-weight: bold;

        &.caps {
            text-transform: uppercase;
        }
    }

    h1 {
        font-size: 18pt;

        &.main-title {
            font-family: arial, sans-serif;
            font-size: 32pt;
            margin: 48pt 0;
        }
    }

    h2 {
        font-size: 14pt;
    }

    h6 {
        font-size: 8pt;
        color: $charcoal2;
        font-weight: bold;
    }

    .image-holder {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        display: inline-block;
        position: relative;
        z-index: 10;

        img {
            width: 100%;
            height: auto;
        }
    }

    div,
    article,
    section,
    p {
        box-sizing: border-box;
    }
}

.us-letter-display {
    /* variable 8.5 x 11 for preview area - base 10px sizing */
    /* stylelint-disable-next-line */
    font-family: ProximaNova;
    background-color: $white;
    width: 61.2em;
    height: 79.2em;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    font-size: 1em;
    line-height: 1.33em;
    color: #2d2d2d;
    text-rendering: geometricprecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.letter-margins {
        padding: 4.8em;
    }

    p {
        margin: 0.8em 0;

        &.footnote {
            color: $charcoal4;
            font-style: italic;
        }
    }

    h1,
    h2 {
        margin: auto;
        color: $sei-dark-blue;
        font-weight: bold;

        &.caps {
            text-transform: uppercase;
        }
    }

    h1 {
        font-size: 1.8em;

        &.main-title {
            font-family: arial, sans-serif;
            font-size: 3.2em;
            margin: 4.8em 0;
        }
    }

    h2 {
        font-size: 1.4em;
    }

    h6 {
        font-size: 0.8em;
        color: $charcoal2;
        font-weight: bold;
    }

    .image-holder {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        display: inline-block;
        position: relative;
        z-index: 10;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.advisor-img {
    position: absolute;
    z-index: 50;
    text-align: center;

    p {
        text-align: center;
    }
}

.background-image {
    background-color: transparent;
    position: absolute;
    z-index: 10;
    width: 552.7pt;
    height: 562pt;
    background-size: cover;
    background-position: bottom right;

    img {
        width: 100%;
        height: auto;
    }
}

.background-image-variable {
    position: absolute;
    z-index: 10;
    width: 61.2em;
    height: 63.6em;
    background-size: cover;
    background-position: bottom right;
    transition: background-image 0.4s ease-in-out;
}

.vert-strip {
    position: absolute;
    z-index: 15;
    background-color: rgb(255 255 255 / 56%);
    inset: 0 292pt 0 70pt;

    .customfor,
    .presentby {
        width: 210pt;

        h2 {
            font-size: 14pt;
            color: $brand-primary;
            margin: 0 0 3pt;
            line-height: 1em;
        }

        p {
            margin: 0;
            line-height: 1.5em;
        }

        position: absolute;
        z-index: 20;
        left: 20pt;
    }

    .customfor {
        bottom: 162pt;
    }

    .presentby {
        top: 578pt;
    }
}

.vert-strip-variable {
    position: absolute;
    z-index: 15;
    inset: 0 29.2em 0 7em;

    #vert-strip-fill {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 10;
        background-color: $white;
        opacity: 0.7;
        transition: all 0.3s ease-in-out;
    }

    .customfor,
    .presentby {
        width: 21em;
        opacity: 1;
        color: $charcoal1;

        h2 {
            font-size: 1.4em;
            color: $charcoal2;
            margin: 0 0 0.3em;
            line-height: 1em;
        }

        p {
            margin: 0;
            line-height: 1.5em;
        }

        position: absolute;
        z-index: 100;
        left: 2em;
    }

    .customfor {
        bottom: 17.2em;
    }

    .presentby {
        top: 65.4em;
    }
}

.title-bar {
    width: 462pt;
    height: 156pt;
    opacity: 0.8;
    position: absolute;
    z-index: 20;
    top: 70pt;
    left: 30pt;
    right: 60pt;
    background-color: $brand-primary;
    padding: 28pt 22pt 0 60pt;
    box-sizing: border-box;

    h1 {
        color: $white;
        font-size: 32pt;
        line-height: 36pt;
        letter-spacing: 0.8;
        transition: color 0.5s ease-out;
    }

    p {
        color: $white;
    }
}

.title-bar-variable {
    width: 52.2em;
    height: 15.6em;
    opacity: 0.88;
    position: absolute;
    z-index: 20;
    top: 9em;
    left: 3em;
    right: 6em;
    background-color: $brand-primary;
    padding: 2.8em 2.2em 0 6em;
    box-sizing: border-box;

    h1 {
        color: $white;
        font-size: 3.2em;
        line-height: 1.2em;
        letter-spacing: 0.8;
        transition: all 0.2s ease-out;
    }

    p {
        color: $white;
    }

    transition: background-color 0.4s ease-in-out;
}

.pres-logo {
    display: block;
    position: absolute;
    z-index: 100;
    bottom: 54pt;
    right: 66pt;

    img {
        max-width: 440pt;
        height: auto;
    }
}

.pres-logo-variable {
    position: absolute;
    z-index: 40;
    bottom: 5em;
    right: 6em;
    max-width: 14em;

    img {
        max-width: 100%;
        height: auto;
    }
}

.upload-call {
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 3.5rem 1rem 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;

    div {
        display: inline-block;
    }

    div:nth-child(1) {
        margin-right: 1rem;
    }
}

#intro-signature {
    margin-top: 48pt;

    p {
        line-height: 2em;
    }
}

.print-row {
    display: flex;
    align-items: center;
    margin: 15pt auto;
}

.client-market-value {
    display: inline-block;
    width: 160pt;
    padding: 10pt 18pt 10pt 14pt;
    border-right: 1px solid $charcoal5;
    box-sizing: border-box;
    margin-right: 20pt;

    p {
        font-size: 12pt;
        color: $charcoal2;
        margin: 0;
        line-height: 1em;
    }

    p:nth-child(2) {
        font-size: 16pt;
        line-height: 1.33em;
    }
}

#main-scenario {
    margin-top: 25pt;
}

.donut {
    display: inline-block;
    width: 80pt;
    min-height: 84pt;
    margin-right: 32pt;
    text-align: center;

    img {
        width: 100%;
        height: auto;
    }
}

.donut-key {
    display: inline-block;
    box-sizing: border-box;
    width: 198pt;
    margin: 0 20pt 0 0;
    font-size: 6.6pt;
    letter-spacing: -0.01em;

    .flex-row {
        align-items: center;
        padding: 1pt 0;
        margin: 0 auto;
    }

    .key-color {
        width: 10pt;
        height: 10pt;
        background-color: $black;
        border-radius: 1pt;
    }

    .key-investment {
        width: 50%;
        align-self: stretch;
    }

    .key-value {
        width: 25%;
    }

    .key-percentage {
        width: 10%;
    }
}

.holdings-row {
    font-size: 8pt;
    line-height: 1.5em;
    position: relative;
    width: 100%;
    display: block;
    padding: 0;

    &.table-header {
        color: $sei-dark-blue;
        margin-top: 10pt;
        border-top: 1px solid $charcoal5;
        border-bottom: 1px solid $charcoal5;

        .holdings-row-left {
            font-weight: normal;
            display: inline-block;
            align-self: center;
        }

        .holdings-row-right {
            border-bottom: none;
            padding-bottom: 0;
            display: inline-block;

            .holdings-row-cell {
                background-color: $charcoal6;
                display: inline-block;
            }
        }
    }

    &.total-row {
        font-weight: bold;
        color: $sei-dark-blue;
        border-top: 1px solid $charcoal5;
        border-bottom: 1px solid $charcoal5;
        margin-top: 2pt;

        .holdings-row-cell {
            padding: 5pt 2.5pt;
        }
    }
}

.holdings-row-right,
.holdings-row-left {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 10;
    display: inline-block;

    .holdings-row-cell {
        padding: 2.5pt 4pt;
        box-sizing: border-box;
        display: inline-block;
    }
}

.holdings-row-left {
    width: 39.5%;
    font-weight: bold;

    h4 {
        font-size: 10pt;
        color: $charcoal3;
        line-height: 1.33em;
        margin: 5pt 0 0;
    }

    &.indent1 {
        padding-left: 10pt;
    }

    &.indent2 {
        padding-left: 20pt;
    }
}

.holdings-row-right {
    width: 59.5%;

    .holdings-row-cell {
        text-align: right;
        width: 19.5%;
    }

    .holdings-row-cell:first-child {
        width: 59%;
        text-align: left;
    }

    &.right-total {
        border-top: 1pt solid $charcoal5;
        padding-top: 2px;
        margin-top: 2pt;
        margin-bottom: 5pt;
        font-weight: bold;
        color: $sei-dark-blue;
    }
}

#main-scenario > .client-market-value {
    text-align: right;

    p:nth-child(2) {
        font-size: 21pt;
    }
}

#main-scenario > .donut {
    width: 146pt;
    margin-right: 0;
    margin-left: 0;
}

#main-scenario > .donut-key {
    width: 165pt;
}

.program-expenses-list,
.definitions-list,
.risk-comparison-summary {
    font-size: 1.2rem;
    line-height: 1em;
    margin: 2rem auto;
    display: block;

    .grp-account {
        article:first-child {
            border-top: 2px solid $charcoal4;
            color: $charcoal1;

            p:last-child {
                color: $sei-dark-blue;
            }
        }

        article:last-child {
            border-bottom: none;
        }
    }

    article {
        color: $charcoal2;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        border-bottom: 1px solid $charcoal5;

        p {
            margin: 0;
            padding: 0.75rem 1rem;

            &.indent {
                padding-left: 2.625rem;
            }
        }

        p:last-child {
            color: $charcoal1;
        }
    }

    article:first-child {
        border-top: 1px solid $charcoal5;
    }

    .def-list-grp {
        article {
            padding: 0.5rem 0;
            margin: 0.5rem auto;

            p {
                width: 29%;
            }

            p:last-child {
                width: 69%;
            }
        }
    }

    .risk-compare-list {
        font-size: 8pt;

        article:first-child {
            font-weight: 700;

            p {
                background-color: $sei-blue-10;
                border-left: 1px solid $charcoal5;
            }

            p:first-child {
                border-left: none;
            }
        }

        article {
            align-items: center;
            padding: 0.25rem 0;

            p {
                width: 12%;
                text-align: center;
                box-sizing: border-box;

                span {
                    display: block;
                    padding: 0.125rem 0;
                }

                &.low,
                &.medium,
                &.high {
                    color: $charcoal1;
                    border-radius: 3pt;
                    padding: 2pt 3pt;
                    box-sizing: border-box;
                    border: 1px solid $white;
                    font-weight: 400;
                }

                &.low {
                    background: $sei-green-50;
                }

                &.medium {
                    background: $sei-orange-50;
                }

                &.high {
                    background: $sei-red-50;
                }
            }

            p:first-child {
                width: 31%;
                text-align: left;
            }

            p:nth-child(4) {
                width: 40%;
                text-align: left;
            }

            p:last-child {
                text-align: left;
            }
        }
    }

    .totals-account {
        border-top: 2px solid $charcoal4;
        border-bottom: 2px solid $charcoal4;
        color: $sei-dark-blue;

        p:last-child {
            color: $sei-dark-blue;
            background-color: $charcoal6;
        }

        font-weight: bold;
    }
}

.selections-default-padding {
    padding-bottom: 1rem;
    padding-top: 1rem;
}
